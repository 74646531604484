import { Button, Text, Box } from '@chakra-ui/react'
import Card from 'components/Card'
import { colors } from '../../../styles'

const DetailForm = ({ setAgeGruop }) => {
  return (
    <Card  title='Select an Age Group'>
      <Box display='grid' gridTemplate="'1 1''1 1'" gridGap={2}>
        <Button onClick={() => { setAgeGruop([0, 'Babies']) }}>Babies (0~3)</Button>
        <Button onClick={() => { setAgeGruop([0, 'Kids']) }}>Kids (4~12)</Button>
        <Button onClick={() => { setAgeGruop([1, 'Teens']) }}>Teens (13~17)</Button>
        <Button onClick={() => { setAgeGruop([2, 'Adults']) }}>Adults (18+)</Button>
      </Box>
    </Card>
  )
}

export default DetailForm
