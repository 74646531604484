import { Stack, Alert, AlertIcon, AlertTitle } from '@chakra-ui/react'
import useUIContext from 'hooks/ui-context'
import { useEffect } from 'react'

const Notifications = () => {
  const { notifications, setNotifications } = useUIContext()

  useEffect(() => {
    const timeouts = notifications.map(notification => {
      return setTimeout(() => {
        setNotifications(notifications.filter(n => n.id !== notification.id))
      }, notification.timeout || 5000)
    })
    return () => {
      timeouts.forEach(t => clearTimeout(t))
    }
  }, [notifications])

  return (
    <Stack position='fixed' right={10} top={10} zIndex='toast'>
      {notifications.map(notification => {
        return (
          <Alert status={notification.status} key={notification.id}>
            <AlertIcon />
            {notification.title && <AlertTitle>{notification.title}</AlertTitle>}
            {notification.content}
          </Alert>
        )
      })}
    </Stack>
  )
}

export default Notifications
