import { useEffect, useState } from 'react'
import { Button, Box, Table, Thead, Tbody, Tr, Td, Th, TableContainer, HStack, Text } from '@chakra-ui/react'
import { useSearchParams, useNavigate } from 'react-router-dom'
import { FaCaretUp, FaEye, FaPen } from 'react-icons/fa'
import { containerWidth } from 'styles'
import Card from 'components/Card'
import useTalent from 'hooks/talent'
import Pagination from 'components/Pagination'
import { formatDate, calcAge, adminSearchFilters } from 'utils'
import useUIContext from 'hooks/ui-context'
import LoadingBar from 'components/LoadingBar'
import Sorter from 'components/Sorter'

const searchFilters = adminSearchFilters

const TalentList = () => {
  const { talents, searchTalents, updateTalentById } = useTalent()
  const navigate = useNavigate()
  const [searchParams, setSearchParams] = useSearchParams()
  const [status, setStatus] = useState(searchParams.get('status') || 'new')
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    totalPages: 1
  })
  const { setBreadcrumbs } = useUIContext()
  const [sort, setSort] = useState({
    first_name: null,
    last_name: null,
    submitted_date: -1,
    updated_at: null
  })

  useEffect(() => {
    setBreadcrumbs([{
      path: '/admin/dashboard',
      label: 'Dashboard'
    }, {
      path: '/admin/dashboard/talents',
      label: 'Talent'
    }])
  }, [])

  const loadData = async () => {
    setLoading(true)
    const { page, perPage } = pagination
    await searchTalents({search:  {...searchFilters[status].filter, page_num: page, per_page: perPage, sort } })
    setSearchParams({ status })
    setLoading(false)
  }
  useEffect(() => {
    loadData()
  }, [status, pagination.page, pagination.perPage, sort])

  useEffect(() => {
    if (status === 'declined') {
      setSort({ ...sort, submitted_date: null, updated_at: -1 })
    } else {
      setSort({ ...sort, submitted_date: -1 })
    }
  }, [status])

  useEffect(() => {
    setPagination({
      ...pagination,
      totalPages: Math.ceil(talents.total_count / talents.per_page)
    })
  }, [talents])

  return (
    <Box width={containerWidth} mx='auto'>
      <Box mb={3} display='grid' gridTemplateColumns={{ base: '1fr 1fr', md: 'repeat(5, 1fr)'}} gap={3}>
        {Object.keys(searchFilters).map(statusKey => {
          return (
            <Button
              key={statusKey}
              variant={status === statusKey ? 'outline': 'text'}
              onClick={() => {
                setStatus(statusKey)
              }}
            >{searchFilters[statusKey].label}</Button>
          )
        })}
      </Box>
      <Card title={searchFilters[status].label} >
        <LoadingBar loading={loading} />
        <TableContainer mb={3}>
          <Table>
            <Thead>
              <Tr>
                {status !== 'new' && ( <Th>Username</Th> )}
                <Th>
                  <HStack>
                    <Text>First name</Text>
                    <Sorter value={sort.first_name} setValue={(v) => {
                      setSort({ ...sort, first_name: v })
                    }} />
                  </HStack>
                </Th>
                <Th>
                  <HStack>
                    <Text>Last name</Text>
                    <Sorter value={sort.last_name} setValue={(v) => {
                      setSort({ ...sort, last_name: v })
                    }}/>
                  </HStack>
                </Th>
                <Th>Age</Th>
                <Th>Email</Th>
                <Th>
                  <HStack>
                    <Text>Submitted</Text>
                    <Sorter value={sort.submitted_date} setValue={(v) => {
                      setSort({ ...sort, submitted_date: v })
                    }}/>
                  </HStack>
                </Th>
                <Th textAlign='center'>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(talents.talents || []).map(talent => {
                return (
                  <Tr style={{fontWeight: (talent.is_viewed_by_admin === false && status === 'new') ? 'bold' : ''}} key={talent._id} onClick={async () => {
                    window.open(`/admin/dashboard/talent/edit/${talent._id}?status=${status}`)
                    await updateTalentById(talent._id, { is_viewed_by_admin: true, by_admin: true })
                    loadData()
                  }}>
                    {status !== 'new' && (
                      <Td>{talent.user && talent.user.username}</Td>
                    )}
                    <Td>{talent.first_name}</Td>
                    <Td>{talent.last_name}</Td>
                    <Td>{calcAge(talent)}</Td>
                    <Td>{talent.email}</Td>
                    <Td>{formatDate(talent.submitted_date)}</Td>
                    {status === 'recently_deleted' ? (
                      <Td textAlign='center'>
                        <Button colorScheme="green" variant="solid" size='xs' onClick={async (ev) => {
                          ev.stopPropagation()
                          ev.preventDefault()
                          await updateTalentById(talent._id, { soft_deleted: false, soft_deleted_at: null, by_admin: true })
                          loadData()
                        }} >
                          Restore
                        </Button>
                      </Td>
                    ) : (
                        <Td textAlign='center'>
                          <Button variant='text' size='xs' onClick={(ev) => {
                            ev.stopPropagation()
                            ev.preventDefault()
                            window.open(`/talent/${talent.slug}`)
                          }} >
                            <FaEye />
                          </Button>
                          <Button variant='text' size='xs' onClick={async (ev) => {
                            ev.stopPropagation()
                            ev.preventDefault()
                            window.open(`/admin/dashboard/talent/edit/${talent._id}`)
                            await updateTalentById(talent._id, { is_viewed_by_admin: true, by_admin: true })
                            loadData()
                          }} >
                            <FaPen />
                          </Button>
                        </Td>
                    )}
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          perPageCountIncludes={['1', '2', '10', '25', '50', '100']}
        />
      </Card>
    </Box>
  )
}

export default TalentList
