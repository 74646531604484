import moment from 'moment'

export const toTitleCase = (str) => {
  return str.replace(
    /\w\S*/g,
    function(txt) {
      return txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()
    }
  )
}

export const getDatePart = (dt) => {
  if (isNaN(+new Date(dt))) {
    return null
  }
  return moment(dt).format('YYYY-MM-DD')
}

export const calcAge = (talent) => {
  const { birth_date: dt, is_over_25  }= talent
  if (dt) {
    const months = Math.floor(moment().diff(moment(dt), 'months', true))
    const years = Math.floor(months / 12)
    return months < 35 ? `${months} Month${months > 1 ? 's' : ''}` :
      years > 17 ? years > 24 ? 'Over 25' : 'Under 25' :
      `${Math.floor(months / 12)} Years`
  } else {
    return is_over_25 ? 'Over 25': 'Under 25'
  }
}

export const formatDate = (dt, format='MM/DD/YYYY') => {
  return moment(dt).format(format)
}

export const adminSearchFilters = {
  new: { label: 'New', filter: { is_accepted: { $exists: false }, accepted_date: null, is_long_term_pending: { $ne: true }, soft_deleted: { $ne: true } } },
  accepted: { label: 'Accepted', filter: { is_accepted: true, is_active: true, is_live: true, is_long_term_pending: { $ne: true }, soft_deleted: { $ne: true } } },
  inactive: { label: 'Inactive', filter: { is_accepted: true, is_active: { $ne: true }, soft_deleted: { $ne: true } }, },
  long_term_pending: { label: 'Long Term Pending', filter: { is_long_term_pending: true, soft_deleted: { $ne: true } } },
  declined: { label: 'Declined', filter: { accepted_date: null, is_accepted: false, is_long_term_pending: { $ne: true }, soft_deleted: { $ne: true } } },
  pending_decision: { label: 'Pending Decision', filter: { accepted_date: { $exists: true, $ne: null }, is_accepted: { $nin: [true, false] }, is_long_term_pending: { $ne: true }, is_talent_postpone: { $exists: false }, soft_deleted: { $ne: true } }},
  decline_from_talent: { label: 'Decline From Talent', filter: { accepted_date: { $exists: true, $ne: null}, is_accepted: false, soft_deleted: { $ne: true } }},
  talent_postponed_count: { label: 'Postpone by Talent', filter: { accepted_date: { $exists: true}, is_talent_postpone: true, soft_deleted: { $ne: true } }},
  recently_deleted: { label: 'Recently deleted', filter: { soft_deleted: true }}
}

export const csvExportFilters = {
  accepted: { label: 'Accepted Talent', filter: { is_accepted: true, is_active: true, is_live: true } },
  inactive: { label: 'Inactive Talent', filter: { is_accepted: true, is_active: { $ne: true } } },
  sag: { label: 'SAG Talent', filter: { union_status: { $in: ['SAG-AFTRA', 'SAG-AFTRA-E'] }} },
  non_union: { label: 'Non Union Talent', filter: { union_status: 'Non Union' } },
}

export const getSearchFilterFromTalent = (talent) => {
  console.log('talent: ', talent);
  let status = {}, len = 0;
  Object.values(adminSearchFilters).forEach(item => {
    const fl = Object.keys(item.filter).filter(filterItemKey => {
      return item.filter[filterItemKey] === talent[filterItemKey]
    }).length
    if (fl > len) {
      len = fl
      status = item
    }
  })
  return status ? status.label : ''
}

export const serializeTalentForForm = (talent) => {
  return {
    ...talent,
    birth_date: getDatePart(talent.birth_date),
    submitted_date: getDatePart(talent.submitted_date),
    accepted_date: getDatePart(talent.accepted_date),
    last_update: getDatePart(talent.last_update),
    contract_exp_date: getDatePart(talent.contract_exp_date),
    work_permit_exp: getDatePart(talent.work_permit_exp)
  }
}

export const getFileAge = (uri) => {
  const r = /tstamp__(\d{13})\./
  try {
    const t = parseInt(uri.match(r)[1])
    return moment(t).format('YYYY-MM-DD')
  } catch (err) { }
  return null
}

export const getOptionLabel = (value, options, isMulti) => {
  return options.filter(item => {
    return isMulti ? (value || []).includes(item.value) : item.value === value
  }).map(v => v.label).join(',')
}

export const copyHtml = (html, parentElem=null) => {
  const tag = document.createElement('textarea')
  tag.innerText = 'a'
  tag.style.position = 'fixed'
  tag.style.left = '-1000px'
  if (parentElem) {
    parentElem.appendChild(tag)
  } else {
    document.body.appendChild(tag)
  }
  const range = document.createRange()
  const selection = getSelection()
  selection.removeAllRanges()
  selection.addRange(range)
  tag.setSelectionRange(0, 999999)

  function listener(e) {
    e.clipboardData.setData("text/html", html);
    e.clipboardData.setData("text/plain", html);
    e.preventDefault();
    if (parentElem) {
      parentElem.removeChild(tag)
    } else {
      document.body.removeChild(tag)
    }
  }
  document.addEventListener("copy", listener);
  document.execCommand("copy");
  document.removeEventListener("copy", listener);
}

export const sizeDisplay = (bytes) => {
  let unit = 0
  const unitText = ['bytes', 'KB', 'MB', 'GB']
  while (bytes > 1024) {
    bytes = bytes / 1024
    unit += 1
  }
  return `${bytes.toFixed(0)}${unitText[unit]}`
}

export const likesDisplay = (likes) => {
  if (!likes) { return '' }
  let unit = 0
  const unitText = ['', 'K', 'M', 'B', 'T', 'QD', 'QN', 'SX', 'SP']
  while (likes > 1000) {
    likes = likes / 1000
    unit += 1
  }
  return `${likes.toFixed(1)}${unitText[unit]}`
}

export function validateYear(value) {
  const year = new Date(value).getFullYear();
  const currentYear = new Date().getFullYear();
  if (year.toString().length !== 4 || year < 1920 || year > currentYear) {
    return "Year must be 4 digits and between 1920 and current year";
  }
  return true;
}
