import { createSlice } from '@reduxjs/toolkit'
import { deleteTalentPhotos, updateTalent, updateTalentBook, uploadTalentResume, searchTalents, deleteTalent,
  deleteTalentResume, uploadTalentPhotos, getTalentById, 
  getTalentBySlug} from 'services'

const talentSlice = createSlice({
  name: 'talent',
  initialState: {
    currentTalent: null,
    talents: [],
    submission: null,
    enumData: null,
    rawEnumData: null
  },
  reducers: {
    setCurrentTalent: (state, action) => {
      if (action.payload !== null) {
        state.currentTalent = {
          birth_date: action.payload?.birth_date ? action.payload?.birth_date : null,
          stage_first_name: action.payload?.stage_first_name ? action.payload?.stage_first_name : null,
          stage_last_name: action.payload?.stage_last_name ? action.payload?.stage_last_name : null,
          is_twin: action.payload?.is_twin ? action.payload?.is_twin : false,
          admin_note: action.payload?.admin_note ? action.payload?.admin_note : null,
          casting_frontier_barcode: action.payload?.casting_frontier_barcode ? action.payload?.casting_frontier_barcode : null,
          sag_aftra_id: action.payload?.sag_aftra_id ? action.payload?.sag_aftra_id : null,
          street_addr: action.payload?.street_addr ? action.payload?.street_addr : null,
          agency: action.payload?.agency ? action.payload?.agency : null,
          flags: action.payload?.flags ? action.payload?.flags : null,
          available_for: action.payload?.available_for ? action.payload?.available_for : null,
          other_abled: action.payload?.other_abled ? action.payload?.other_abled : false,
          ...action.payload
        }
      } else {
        state.currentTalent = action.payload
      }
    },
    setSubmission: (state, action) => {
      state.submission = action.payload
    },
    setTalents: (state, action) => {
      state.talents = action.payload
    },
    setEnumData: (state, action) => {
      const height = []
      state.rawEnumData = action.payload
      for (let h = action.payload.height.min_value; h <= action.payload.height.max_value; h ++) {
        height.push({
          label: `${Math.floor(h/12)}' ${h%12}"`,
          value: h
        })
      }
      const weight = []
      for (let w = action.payload.weight.min_value; w <= action.payload.weight.max_value; w ++) {
        weight.push({
          label: `${w} lbs`,
          value: w
        })
      }
      state.enumData = {
        ...action.payload,
        weight,
        height
      }
    }
  }
})

export const updateAndSetTalent = async (talentId, talent, dispatch) => {
  const talentRes = await updateTalent(talentId, talent)
  if (talentRes.data.talent) {
    dispatch(setCurrentTalent(talentRes.data.talent))
  }
  return talentRes.data
}

export const updateAndSetTalentBook = async (talentId, talent, dispatch) => {
  const talentRes = await updateTalentBook(talentId, talent)
  if (talentRes.data.talent) {
    dispatch(setCurrentTalent(talentRes.data.talent))
  }
  return talentRes.data
}

export const deleteAndSetTalent = async (talentId, dispatch) => {
  await deleteTalent(talentId)
  dispatch(setCurrentTalent(null))
}

export const uploadPhotosAndSetTalent = async (talentId, formData, dispatch) => {
  const talentRes = await uploadTalentPhotos(talentId, formData)
  dispatch(setCurrentTalent(talentRes.data))
}

export const deletePhotosAndSetTalent = async (talentId, photos, by_admin, dispatch) => {
  const talentRes = await deleteTalentPhotos(talentId, photos, by_admin)
  dispatch(setCurrentTalent(talentRes.data))
}

export const uploadResumeAndSetTalent = async (talentId, formData, dispatch) => {
  const talentRes = await uploadTalentResume(talentId, formData)
  dispatch(setCurrentTalent(talentRes.data))
}

export const deleteResumeAndSetTalent = async (talentId, by_admin, dispatch) => {
  const talentRes = await deleteTalentResume(talentId, by_admin)
  dispatch(setCurrentTalent(talentRes.data))
}

export const searchAndSetTalents = async (search, dispatch) => {
  const talentsRes = await searchTalents(search)
  dispatch(setTalents(talentsRes.data))
}

export const getTalentByIdSetTalent = async (talentId, dispatch) => {
  const talentRes = await getTalentById(talentId)
  dispatch(setCurrentTalent(talentRes.data))
}
export const getTalentBySlugSetTalent = async (slug, dispatch) => {
  const talentRes = await getTalentBySlug(slug)
  dispatch(setCurrentTalent(talentRes.data))
}

export const {
  setCurrentTalent,
  setSubmission,
  setTalents,
  setEnumData,
} = talentSlice.actions

export default talentSlice.reducer
