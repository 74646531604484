import { Box, Text, Link, IconButton } from '@chakra-ui/react'
import { Link as ReactRouterLink } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { containerWidth } from 'styles'
import Card from 'components/Card'
import { FaPhotoVideo, FaExclamationTriangle, FaCopy,
FaClipboardList, FaFilePdf, FaCalendar, FaUser, FaUsers } from 'react-icons/fa'
import { IoDocumentSharp } from 'react-icons/io5'
import { VscReferences } from 'react-icons/vsc'
import { RiContactsBookFill } from 'react-icons/ri'
import useUIContext from "hooks/ui-context";

const TalentDashboard = () => {
  const user = useSelector(state => state.user.currentUser)
  const talent = useSelector(state => state.talent.currentTalent)
  const navigate = useNavigate()
  const { addNotification } = useUIContext();

  if (!user || !talent) { return null }

  const iconSize = 52

  const talentName = talent.is_accepted
    ? `${talent.stage_first_name || talent.first_name} ${talent.stage_last_name || talent.last_name}`
    : `${talent.first_name} ${talent.last_name}`

  return (
    <Box width={containerWidth} mx='auto'>
      <Box mb={5} display='flex' justifyContent='space-between'>
        <Text fontSize='xl'>
          {talentName}
        </Text>

        <IconButton
          variant='ghost' ml={'auto'} mr={3}
          onClick={() => {
            navigator.clipboard.writeText(`${window.location.origin}/talent/${talent.slug}`)
            addNotification({
              status: 'success',
              content: 'Link is copied!'
            });
          }}
        >
          <FaCopy size={20} />
        </IconButton>
    
        <Link
          fontSize='xl'
          sx={{textDecoration:'underline'}}
          as={ReactRouterLink}
          to={`/talent/${talent.slug}`}
          target="_blank" 
          rel="noopener noreferrer">Public Profile</Link>
      </Box>
      <Box display='grid' gridTemplateColumns={{base: '1fr', md: '1fr 1fr', lg: '1fr 1fr 1fr'}} gridRowGap={5} gridColumnGap={8}>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='Documents to Return' 
        >
          <a href='https://palomamodelandtalent.com/talent-information/#documents' target='_blank' rel='noreferrer'>
            <IoDocumentSharp size={iconSize} />
          </a>
        </Card>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='Reference Materials'
        >
          <a href='https://palomamodelandtalent.com/talent-information/#reference-materials' target='_blank' rel='noreferrer'>
            <VscReferences size={iconSize} />
          </a>
        </Card>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='Agency Contact information'
        >
          <a href='https://palomamodelandtalent.com/talent-information/#agency-contacts' target='_blank' rel='noreferrer'>
            <RiContactsBookFill size={iconSize} />
          </a>
        </Card>

        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='My Photos'
          onClick={() => { navigate('photos') }}
        >
          {talent?.photos?.length === 0 ?
            <Box display='flex' flexDirection='column' alignItems='center' w='100%'>
              <FaExclamationTriangle size={iconSize}/>
              <Text textAlign='center'>
                You will need to have at least one photo uploaded for your profile to be reviewed.
              </Text>
            </Box>
          : (
            <FaPhotoVideo size={iconSize}/>
          )}
        </Card>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='My Details' onClick={() => { navigate('details') }}
        >
          <FaClipboardList size={iconSize} />
        </Card>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='Resume' onClick={() => { navigate('resume') }}
        >
          <FaFilePdf size={iconSize} />
        </Card>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='Book-Out Dates' onClick={() => { navigate('bookout') }}
        >
          <FaCalendar size={iconSize} />
        </Card>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='My Address' onClick={() => { navigate('address') }}
        >
          <FaUser size={iconSize} />
        </Card>
        <Card
          bodyProps={{ display: 'flex', justifyContent: 'center' }}
          containerProps={{ cursor: 'pointer' }}
          title='Contact Information' onClick={() => { navigate('contacts') }}
        >
          <FaUsers size={iconSize} />
        </Card>
      </Box>
    </Box>
  )
}

export default TalentDashboard
