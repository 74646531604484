import { useState } from 'react'
import { Text, ModalHeader, Button, Modal, ModalOverlay,
  ModalContent, ModalBody, ModalCloseButton, ModalFooter } from '@chakra-ui/react'
import useUIContext from 'hooks/ui-context'

const ConfirmModal = () => {
  const [processing, setProcessing] = useState(false)

  const { confirmModal, showConfirmModal } = useUIContext()

  const onClose = () => {
    if (processing) { return }
    showConfirmModal({ show: false })
  }

  const onAction = async (action) => {
    setProcessing(true)
    if (confirmModal.callback) {
      await confirmModal.callback(action, confirmModal.payload)
    }
    setProcessing(false)
    setTimeout(onClose)
  }

  return (
    <Modal isOpen={confirmModal.show} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>
          {confirmModal.title}
        </ModalHeader>
        <ModalCloseButton onClick={onClose} disabled={processing} />
        <ModalBody>
          <Text>{confirmModal.content}</Text>
        </ModalBody>

        <ModalFooter>
          {confirmModal.buttons.map(button => {
            return (
              <Button key={button.action} colorScheme={button.color} ml={3} onClick={() => {
                onAction(button.action)
              }} disabled={processing}>
                {button.label}
              </Button>
            )
          })}
        </ModalFooter>
      </ModalContent>
    </Modal>
  )
}

export default ConfirmModal
