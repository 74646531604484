import { Text, Box, IconButton } from '@chakra-ui/react'
import useStyle from 'hooks/style.'
import { FaTimes } from 'react-icons/fa'
import { colors } from 'styles'

const Card = ({ title, children, bodyProps={ }, onHeaderClick,
  onClick, onClose, showClose, containerProps={}, rightIcon=null
}) => {
  const { darkColor } = useStyle()

  return (
    <Box
      display='flex'
      alignItems='center'
      flexDirection='column'
      borderColor={darkColor}
      borderWidth={1}
      borderRadius={5}
      {...containerProps}
      onClick={ev => onClick && onClick(ev)}
    >
      <Box display='flex' bg={darkColor} w='100%' alignItems='center' px={4} color={colors.primaryText}>
        <Text
          width='100%'
          textAlign={rightIcon ? 'left': 'center'}
          fontWeight='bold'
          py={2}
          onClick={(ev) => onHeaderClick && onHeaderClick(ev)}
        >
          {title}
        </Text>
        {showClose && (
          <IconButton position='absolute' right='0' bg={darkColor}  onClick={() => {onClose && onClose()}}>
            <FaTimes />
          </IconButton>
        )}
        {rightIcon}
      </Box>
      <Box m={7} {...{width: '100%', px: 7, ...bodyProps}}>
        {children}
      </Box>
    </Box>
  )
}

export default Card
