import React, { useEffect, useRef } from 'react'
import moment from 'moment'
import { Input } from '@chakra-ui/react'
import { useController } from 'react-hook-form'
import { getDatePart } from 'utils'

const ControlledDateInput = ({ control, name, label, rules, ...props }) => {
  const {
    field: { onChange, value, ref },
  } = useController({
    name,
    control,
    rules
  });
  const inputRef = useRef(null)

  let parsedValue = getDatePart(value) || ''

  if (parsedValue === 'Invalid date') {
    parsedValue = ''
  }

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.value = parsedValue
    }
  }, [value])

  return (
    <Input
      name={name}
      type='date'
      ref={r => { ref(r); inputRef.current = r }}
      onChange={ev => {
        onChange(moment(ev.target.value).toDate())
      }}
      // value={parsedValue}
      {...props}
      opacity={parsedValue ? 1 : 0.5}
      defaultValue=""
      disabled={props.disabled}
    />
  );
};

export default ControlledDateInput
