import { Progress } from '@chakra-ui/react'

const LoadingBar = ({ loading }) => {
  return (
    <Progress
      size='xs'
      isIndeterminate={loading}
      bgColor='transparent'
    />
  )
}

export default LoadingBar
