import axiosInstance from './service'

const login = async (username, password) => {
  const res = await axiosInstance.post('/auth/login', {
    username, password
  })
  return res
}

const getLoggedInUser = async () => {
  const res = await axiosInstance.get('/auth/me')
  return res  
}

const searchClients = async (search) => {
  const res = await axiosInstance.post('/auth/user/clients', search)
  return res
}

const updateUser = async (id, fields) => {
  const res = await axiosInstance.put(`/auth/user/${id}`, { user: fields })
  return res
}

const registerUser = async (fields) => {
  const res = await axiosInstance.post('/auth/register', fields)
  return res
}

const deleteUser = async (id) => {
  const res = await axiosInstance.delete(`/auth/user/${id}`)
  return res
}

const resetPasswordRequest = async (username, resetUsername) => {
  const res = await axiosInstance.post(`/auth/reset-password-request?username=${username}&resetUsername=${resetUsername}`)
  return res
}

const resetPassword = async (data) => {
  const res = await axiosInstance.post(`/auth/reset-password`, data)
  return res
}

const updateUsername= async (data) => {
  const res = await axiosInstance.post(`/auth/username`, data)
  return res
}

const searchAdmins = async (search) => {
  const res = await axiosInstance.post('/auth/user/admins', search)
  return res
}

const registerAdminUser = async (fields) => {
  const res = await axiosInstance.post('/auth/register-admin', fields)
  return res
}

export {
  login,
  updateUser,
  deleteUser,
  registerUser,
  searchClients,
  getLoggedInUser,
  resetPasswordRequest,
  resetPassword,
  updateUsername,
  searchAdmins,
  registerAdminUser
}
