import { useEffect, useRef, useState } from 'react'
import { Text, FormControl, FormLabel, FormErrorMessage, Button, Box, Image, Badge } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd'
import { containerWidth } from 'styles'
import useTalent from 'hooks/talent'
import { API_URL } from 'constants'
import useUIContext from 'hooks/ui-context'
import { getFileAge, sizeDisplay } from 'utils'
import { MIN_FILE_SIZE, MAX_FILE_SIZE, MAX_PHOTO_COUNT } from 'constants'
import QuestionImg from 'asset/question.png'

const reorder = (list, startIndex, endIndex) => {
  const result = Array.from(list)
  const [removed] = result.splice(startIndex, 1)
  result.splice(endIndex, 0, removed)

  return result
};

const PhotoUpload = ({ by_admin }) => {
  const {
    talent,
    updateTalent,
    uploadTalentPhotos: uploadPhotos,
    deleteTalentPhotos: deletePhotos
  } = useTalent()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const {showConfirmModal} = useUIContext()
  const [apiError, setApiError] = useState('')
  const [uploadResult, setUploadResult] = useState('')

  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm()
  const formRef = useRef(null)
  const [currentPhoto, setCurrentPhoto] = useState(0)
  const [photos, setPhotos] = useState([])

  useEffect(() => {
    if (talent) {
      if (!currentPhoto) {
        setCurrentPhoto(filterPhotos(talent.photos)[0])
      }
      setPhotos(filterPhotos(talent.photos))
    }
  }, [currentPhoto, talent])

  if (!talent) return null

  const handleUpload = async () => {
    setIsSubmitting(true)
    try {
      const photos = Array.from(document.querySelector('#new-photo-upload').files).slice(0, MAX_PHOTO_COUNT - talent.photos.length)
      const formData = new FormData()
      let count = 0
      for (let i = 0; i < photos.length; i ++) {
        if (photos[i].size < MAX_FILE_SIZE && photos[i].size > MIN_FILE_SIZE) {
          formData.append('files', photos[i])
          count += 1
        }
      }
      formData.append('by_admin', by_admin)
      setUploadResult(`Uploading ${count} files.`)
      await uploadPhotos(formData)
      setUploadResult(`${count} files uploaded.`)
      reset()
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
  }

  const onDragEnd = async (result) => {
    if (!result.destination) {
      return
    }

    if (result.destination.index === result.source.index) {
      return
    }

    const updatedPhotos = reorder(
      talent.photos,
      result.source.index,
      result.destination.index
    )

    setIsSubmitting(true)
    setPhotos(filterPhotos(updatedPhotos))
    try {
      await updateTalent({ photos: updatedPhotos })
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
  }

  const filterPhotos = photos => photos.filter(v => !!v);

  const deleteConfirmHandle = async (action) => {
    if (action !== 'yes') { return }
    await deletePhotos([currentPhoto], by_admin)
  }

  return (
    <Box width={containerWidth} mx='auto'>
      <form ref={formRef} onSubmit={handleSubmit(handleUpload)}>
        <FormControl isInvalid={errors && errors.files} >
          <FormLabel>Edit Photos</FormLabel>
            <Box display='flex' alignItems='end'>
              <input
                multiple
                type='file'
                id="new-photo-upload"
                name='files'
                accept='image/png, image/gif, image/jpeg'
                {...register("files", { required: 'Please choose at least one file'})}
                disabled={isSubmitting}
                onChange={() => {
                  handleUpload()
                }}
                style={{ display: 'none' }}
              />
              <Button
                disabled={isSubmitting}
                isLoading={isSubmitting}
                ml={3}
                onClick={() => {
                  document.querySelector('#new-photo-upload').click()
                }}
              >Upload New Photo</Button>
              <Text my={3} ml={3}>
                {uploadResult}
              </Text>
            </Box>
          <FormErrorMessage>{(errors.files && errors.files.message)}</FormErrorMessage>
          <Text color='red'>{`${apiError}`}</Text>
          <Text mt={3}>
            You can upload at most {MAX_PHOTO_COUNT} photos with max file size of {sizeDisplay(MIN_FILE_SIZE)} ~ {sizeDisplay(MAX_FILE_SIZE)}
          </Text>
          <Text mb={1}>
            Drag thumbnail photos on the left to change the order.
          </Text>
        </FormControl>
      </form>

      <Box mt={7} display='grid' gridTemplateColumns={'auto 1fr'} gridGap={3}>
        <DragDropContext onDragEnd={onDragEnd}>
          <Droppable droppableId='list'>
            {(provided) => (
              <Box
                {...provided.droppableProps}
                ref={provided.innerRef}
              >
                {photos.map((item, index) => (
                  <Draggable key={item} draggableId={item} index={index}>
                    {(provided) => (
                      <Box
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                        py={3}
                        onClick={() => { setCurrentPhoto(`${item}`) }}
                        position='relative'
                      >
                        <Badge
                          variant='solid'
                          position='absolute'
                          left={1}
                          top={0}
                          colorScheme='green'
                          borderRadius={3}
                          fontSize={16}
                        >
                          {index + 1}
                        </Badge>
                        <Image
                          width={120}
                          borderRadius={5}
                          boxShadow={item === currentPhoto ? 'outline' : ''}
                          overflow='hidden'
                          src={`${API_URL}/s3/${encodeURIComponent('150x150/' + item)}`}
                          onError={({currentTarget :elem}) => elem.src = QuestionImg}
                        />
                        <Text width={120} whiteSpace='break-spaces'>{getFileAge(item)}</Text>
                      </Box>
                    )}
                  </Draggable>
                ))}
                {provided.placeholder}
              </Box>
            )}
          </Droppable>
        </DragDropContext>

        <Box
          borderRadius={5}
          overflow='hidden'
          m={3} p={7}
          position='sticky'
          top={10}
          bg='gray.100'
          height='90vh'
          display='flex'
          alignItems='center'
          justifyContent='center'
        >
          {currentPhoto ? <Image 
            borderRadius={10}
            overflow='hidden'
            maxHeight='100%'
            src={`${API_URL}/s3/${encodeURIComponent(currentPhoto)}`}
            onError={({currentTarget :elem}) => elem.src = QuestionImg}
          />: <Box>
            <Text>
              You need to upload at least one photo.
            </Text>
          </Box>}
          {currentPhoto && (
            <Button
              position='absolute'
              top={3}
              right={3}
              colorScheme='red'
              opacity={0.3}
              _hover={{opacity: 1}}
              onClick={() => {
                showConfirmModal({
                  title: 'Are you sure?',
                  content: 'You won\'t be able to revert this action!',
                  buttons: [{
                    label: 'Yes',
                    action: 'yes',
                    color: 'red'
                  }, {
                    label: 'Cancel',
                    action: 'cancel'
                  }],
                  callback: deleteConfirmHandle
                })
              }}
            >Delete</Button>
          )}
        </Box>
      </Box>
    </Box>
  )
}

export default PhotoUpload
