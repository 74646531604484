import { useContext, useEffect } from 'react'
import { useLocation, useNavigate } from 'react-router-dom'
import { Outlet } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { Breadcrumb, BreadcrumbItem, BreadcrumbLink, Box } from '@chakra-ui/react'
import Header from 'components/Header'
import { USER_TYPES } from 'constants'
import { getTalentByUser } from 'services'
import { setCurrentTalent } from 'store/modules/talent'
import { toTitleCase } from 'utils'
import { UIContext } from 'UIProvider'

const UserLayout = () => {
  const user = useSelector(state => state.user.currentUser)
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const uiContext = useContext(UIContext)

  const { breadcrumbs } = uiContext.state

  useEffect(() => {
    const initUserData = async () => {
      if (user) {
        if (user.user_type === USER_TYPES.TALENT) {
          const talentRes = await getTalentByUser(user._id)
          dispatch(setCurrentTalent(talentRes.data))
        }
      }
    }
    initUserData()
  }, [dispatch, user])

  useEffect(() => {
    try {
      document.title = breadcrumbs[breadcrumbs.length - 1].label
    } catch (err) {
      document.title = 'Paloma'
    }
  }, [breadcrumbs])

  const s = location.pathname
  const breadcrumbPaths = s.split('/').map((_, i) => s.split('/').slice(0, i + 1).join('/')).slice(2).map(path => {
    return {
      path,
      label: toTitleCase(path.split('/').slice(-1)[0])
    }
  })

  const bs = breadcrumbs.length > 0 ? breadcrumbs : breadcrumbPaths

  return (
    <Box>
      <Header user={user} />
      <Box px={7} py={3}>
        {user && <Breadcrumb>
          {bs.map(path => {
            return (
              <BreadcrumbItem key={path.path}>
                <BreadcrumbLink onClick={() => {
                  navigate(path.path)
                }}>
                  {path.label}
                </BreadcrumbLink>
              </BreadcrumbItem>
            )
          })}
        </Breadcrumb>}
      </Box>
      <Box>
        <Outlet />
      </Box>
    </Box>
  )
}

export default UserLayout
