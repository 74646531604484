import React, { useRef } from 'react'
import { Box, Button, HStack, Text } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import useUIContext from 'hooks/ui-context'
import Card from 'components/Card'
import { copyHtml, getDatePart, getOptionLabel } from 'utils'
import { FaCopy } from 'react-icons/fa'

const TalentBookInfo = ({ talent, close }) => {
  const enumData = useSelector(state => state.talent.enumData)
  const user = useSelector(state => state.user.currentUser)
  const {addNotification} = useUIContext()
  const cardRef = useRef()

  return (
    <Card
      title={`${talent.first_name} ${talent.last_name} Booking Information`}
      bodyProps={{width: '100%', px: 0, mb: 0}}
    >
      <Box px={7}  ref={cardRef}>
        <div id='talent-booking-information'>
          <div>
            <label>Legal Name:</label>&nbsp;
            <strong>{talent.first_name} {talent.last_name}</strong>
          </div>
          <div>
            <label>Stage Name:</label>&nbsp;
            <strong>{talent.stage_first_name} {talent.stage_last_name}</strong>
          </div>
          <div>
            <label>DOB:</label>&nbsp;
            <strong>{getDatePart(talent.birth_date)}</strong>
          </div>
          <br/>
          <div>
            <label>Work Permit Expiration Date:</label>&nbsp;
            <strong>{getDatePart(talent.work_permit_exp)}</strong>
          </div>
          <div>
            <label>Union Status:</label>&nbsp;
            <strong>{getOptionLabel(talent.union_status, enumData.union)}</strong>
          </div>
          <br/>
          <strong>Contact information:</strong>
          <div>
            <label>First phone number</label>&nbsp;
            <strong>{talent.phones[0]}</strong>
          </div>
          <div>
            <label>Main Account Email</label>&nbsp;
            <strong>{talent.email}</strong>
          </div>

          <div>
            <label>- Contacts -</label>&nbsp;
            {talent.contacts.map(c => {
              return (
                <div key={c._id}>
                  <div>{c.relationship} &nbsp;&nbsp; {c.first_name} {c.last_name} </div>
                  <div>{c.cell_number}</div>
                </div>
              )
            })}
          </div>

          {talent.address && (
            <div>
              {talent.address.city}&nbsp;
              {talent.address.state}&nbsp;
              {talent.address.zip}
            </div>
          )}
          <br/>

          <div>
            <label>Agent Name</label>&nbsp;
            <strong>{user.first_name} {user.last_name}</strong>
          </div>

          <br/>
          <div>
            AGENCY: PALOMA MODEL &amp; TALENT
          </div>

          <div>
            <label>Phone:</label>&nbsp;
            <span>+1 323-800-7500</span>
          </div>
          <div>
            <label>Email:</label>&nbsp;
            <span>{user.email}</span>
          </div>

          <br />
          <div>
            <div>1600 Rosecrans Avenue </div>
            <div>Media Center, 4th Floor </div>
            <div>Manhattan Beach, CA 90266</div>
          </div>
        </div>
      </Box>
      <Button w='100%' mt={7} leftIcon={<FaCopy />} onClick={() => {
        const html = document.querySelector('#talent-booking-information').innerHTML
        copyHtml(html, cardRef.current)
        addNotification({
          status: 'success',
          content: 'Copied'
        })
        close()
      }}>
        Copy Info
      </Button>
    </Card>
  )
}

export default TalentBookInfo
