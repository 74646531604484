import { useEffect, useRef, useState } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { Box, Button, HStack, Select, Text } from '@chakra-ui/react'
import JSONEditor from 'jsoneditor';
import useUIContext from 'hooks/ui-context'
import 'jsoneditor/dist/jsoneditor.css';
import { getEnumValue, updateEnumValues } from 'services'
import { setEnumData } from 'store/modules/talent'
import { setRootLoading } from 'store/modules/app'

const modes = ['view', 'tree', 'form', 'code', 'text', 'preview']

const EnumEdit = () => {
  const containerRef = useRef(null)
  const enumData = useSelector(state => state.talent.rawEnumData)
  const editor = useRef(null)
  const [mode, setMode] = useState('view')
  const [error, setError] = useState(false)
  const data = useRef(JSON.parse(JSON.stringify(enumData)))
  const dispatch = useDispatch()
  const { setBreadcrumbs } = useUIContext()

  useEffect(() => {
    if (!editor.current) {
      editor.current = new JSONEditor(containerRef.current, {
        mode,
        onChangeText: (txt) => {
          try {
            data.current = JSON.parse(txt)
            setError(false)
          } catch (err) {
            setError(true)
          }
        },
      })
      editor.current.set(enumData)
    }
    setBreadcrumbs([{
      path: `/admin/dashboard`,
      label: 'Dashboard'
    }])
  }, [])

  useEffect(() => {
    if (editor.current) {
      editor.current.setMode(mode)
    }
  }, [mode])

  const handleSave = async () => {
    dispatch(setRootLoading(true))
    try {
      await updateEnumValues(data.current)
      const enumRes = await getEnumValue()
      dispatch(setEnumData(enumRes.data))
    } catch (error) {
      console.log('error: ', error);
    }
    dispatch(setRootLoading(false))
  }

  return (
    <Box px={7}>
      <HStack justifyContent='space-between' mb={3}>
        <Text>
          Please make sure you know what you are doing! If you remove existing entry, all talent data related with the entry will be broken!
        </Text>
        <HStack>
          <Text whiteSpace='nowrap'>
            Select editor mode
          </Text>
          <Select onChange={ev => {
            setMode(ev.target.value)
          }}>
            {modes.map(m => {
              return (
                <option key={m} value={m}>{m}</option>
              )
            })}
          </Select>
        </HStack>
      </HStack>
      <Box h='70vh' className="jsoneditor-react-container" ref={containerRef} />
      <Button mt={5} disabled={error} onClick={handleSave}>
        Save
      </Button>
    </Box>
  )
}

export default EnumEdit
