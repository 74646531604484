import { FormControl, FormLabel, Button, Box, Input, HStack, PopoverContent, PopoverTrigger, Popover } from '@chakra-ui/react'
import { useSelector } from 'react-redux'
import { set, useForm } from 'react-hook-form'
import Select from 'components/Select'
import { useEffect } from 'react'
import { USER_TYPES } from 'constants'
import DateInput from 'components/DateInput'
import MultiDatePicker, {Calendar } from "react-multi-date-picker";
import DatePanel from "react-multi-date-picker/plugins/date_panel" 
import useAuthorization from 'hooks/use-authorization'
import { useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { setToken } from 'store/modules/user'

const FormControlItem = (props) => {
  return (
    <FormControl {...props} minW={52} flex={1}>
      {props.children}
    </FormControl>
  )
}

const ClientSearch = ({
  search = {},
  searchSubmit = () => {}
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { isAuthenticated } =  useAuthorization();
  const enumData = useSelector(state => state.talent.enumData)
  const user = useSelector(state => state.user.currentUser)

  const {
    control,
    handleSubmit,
    register,
    setValue,
    reset,
    watch
  } = useForm({
    is_roster_search: false,
    avaibility: []
  })
  useEffect(() => {
    if (search) {
      reset({
        avaibility: [],
        is_roster_search: true,
        ...search
      })
    }
  }, [JSON.stringify(search)])

  const avaibility= watch('avaibility')
  const watchedFields = watch();

  useEffect(() => {
    if (!isAuthenticated()) {
      dispatch(setToken(null))
      navigate('/auth/login')
    }
  }, [watchedFields]);

  console.log('enumData', enumData)

  return (
    <form onSubmit={handleSubmit(searchSubmit)} w="100%">
      <Box display='flex' flexWrap='wrap' gridGap={3}>
        {user && user.user_type === USER_TYPES.ADMIN && (
          <FormControl minW='100%'>
            <FormLabel>Search Option</FormLabel>
            <Select control={control}
              name='is_roster_search'
              options={[{
                label: 'Roster',
                value: true
              }, {
                label: 'All',
                value: false
              }]} 
              onChange={({value}) => {
                setValue('is_roster_search', value)
              }}
            />
          </FormControl>
        )}
        <FormControlItem>
          <FormLabel>First Name</FormLabel>
          <Input {...register('first_name')}/>
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Last Name</FormLabel>
          <Input {...register('last_name')}/>
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Gender</FormLabel>
          <Select control={control}
            isMulti
            name='gender'
            options={enumData.gender}
          />
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Is identical twin</FormLabel>
          <Select control={control}
            name='is_twin'
            options={enumData.boolean_value}
          />
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Union Status</FormLabel>
          <Select control={control}
            isMulti
            name="union"
            options={enumData.union}
          />
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Eye Color</FormLabel>
          <Select control={control}
            isMulti
            name='eye_color'
            options={enumData.eye_color} 
          />
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Hair Color</FormLabel>
          <Select control={control}
            isMulti
            name='hair_color'
            options={enumData.hair_color} 
          />
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Clothing Size</FormLabel>
          <Select control={control}
            isMulti
            name='cloth_size'
            options={enumData.cloth_size} 
          />
        </FormControlItem>
        <FormControlItem>
          <FormLabel>Shoe Size</FormLabel>
          <Select control={control}
            isMulti
            name='shoe_size'
            options={enumData.shoe_size} 
          />
        </FormControlItem>
        {/* <FormControlItem>
          <FormLabel>Region</FormLabel>
          <Select control={control}
            isMulti
            name='area'
            options={[{
              label: 'Los Angles County',
              value: 'LA'
            }, {
              label: 'Orange County',
              value: 'orange'
            }, {
              label: 'San Diego County',
              value: 'San Diego'
            }]} 
          />
        </FormControlItem> */}
        <FormControlItem>
          <FormLabel>Job Type</FormLabel>
          <Select control={control}
            isMulti
            name='available_for'
            options={[{
              label: 'Theatrical',
              value: 't'
            }, {
              label: 'Print',
              value: 'p'
            }, {
              label: 'Commercial',
              value: 'c'
            }]}
          />
        </FormControlItem>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          <FormControlItem>
            <FormLabel textAlign='center'>Profile updated in the last x months</FormLabel>
            <Input type='number' {...register('last_updated_months')}/>
          </FormControlItem>
          <FormControlItem>
            <FormLabel textAlign='center'>Profile not updated in the last x months</FormLabel>
            <Input type='number' {...register('not_updated_last_months')}/>
          </FormControlItem>
        </HStack>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          <FormControlItem>
            <FormLabel>Min Age</FormLabel>
            <Select control={control}
              name='min_age'
              options={enumData.age} 
            />
          </FormControlItem>
          <FormControlItem>
            <FormLabel>Max Age</FormLabel>
            <Select control={control}
              name='max_age'
              options={enumData.age} 
            />
          </FormControlItem>
        </HStack>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          <FormControlItem>
            <FormLabel>Min Weight</FormLabel>
            <Select control={control}
              name='min_weight'
              options={enumData.weight} 
            />
          </FormControlItem>
          <FormControlItem>
            <FormLabel>Max Weight</FormLabel>
            <Select control={control}
              name='max_weight'
              options={enumData.weight} 
            />
          </FormControlItem>
        </HStack>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          <FormControlItem>
            <FormLabel>Min Height</FormLabel>
            <Select control={control}
              name='min_height'
              options={enumData.height} 
            />
          </FormControlItem>
          <FormControlItem>
            <FormLabel>Max Height</FormLabel>
            <Select control={control}
              name='max_height'
              options={enumData.height} 
            />
          </FormControlItem>
        </HStack>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          {user && user.user_type === USER_TYPES.ADMIN && (
            <FormControlItem>
              <FormLabel>Additional Search</FormLabel>
              <Select control={control}
                isMulti
                name='additional_searches'
                options={enumData.additional_searches} 
              />
            </FormControlItem>
          )}
          <FormControlItem>
            <FormLabel>Influencer</FormLabel>
            <Select control={control}
              name='influencer'
              options={enumData.influencer} 
            />
          </FormControlItem>
        </HStack>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          <FormControlItem>
            <FormLabel>Body Type</FormLabel>
            <Select control={control}
              isMulti
              name='body_type'
              options={enumData.body_type} 
            />
          </FormControlItem>
          <FormControlItem>
            <FormLabel>Special Skills</FormLabel>
            <Select control={control}
              isMulti
              name='special_skills'
              options={enumData.skills} 
            />
          </FormControlItem>
        </HStack>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          <FormControlItem>
            <FormLabel>Ethnic Heritage (actual)</FormLabel>
            <Select control={control}
              isMulti
              name='ethnicity_actual'
              options={enumData.ethnicity} 
            />
          </FormControlItem>
          <FormControlItem>
            <FormLabel>Racial Appearance</FormLabel>
            <Select control={control}
              isMulti
              name='ethnicity_look'
              options={enumData.racial_appearance} 
            />
          </FormControlItem>
        </HStack>
        <HStack flex={1} flexDirection={{base: 'column', sm: 'row'}}>
          <FormControlItem>
            <FormLabel>Is Homeschooled</FormLabel>
            <Select control={control}
              isMulti
              name='is_homeschooled'
              options={enumData.boolean_value} 
            />
          </FormControlItem>
          <FormControlItem>
            <FormLabel>Other abled?</FormLabel>
            <Select control={control}
              isMulti
              name='other_abled'
              options={enumData.boolean_value}
            />
          </FormControlItem>
        </HStack>
        <HStack>
          <FormControlItem>
            <FormLabel>Region</FormLabel>
            <Select control={control}
              isMulti
              name='region'
              options={enumData.region} 
            />
          </FormControlItem>
          <FormControlItem>
            <FormLabel>Submitted After</FormLabel>
            <Input
              type='date'
              {...register('submitted_date')}
              name='submitted_date'
            />
          </FormControlItem>
        </HStack>
        <FormControlItem>
          <FormLabel>Available dates</FormLabel>
          <Popover>
            <PopoverTrigger>
              <Button>
                {(!avaibility || avaibility.length === 0) ? 'Not required' : `${avaibility.length} dates selected`}
              </Button>
            </PopoverTrigger>
            <PopoverContent>
              <Calendar
                value={avaibility}
                multiple={true}
                onChange={v => {
                  setValue('avaibility', v.map(d => new Date(d.unix * 1000)))
                }}
                plugins={[
                  <DatePanel sort="date" />
                ]}
              />
            </PopoverContent>
          </Popover>
        </FormControlItem>
        <Box w='100%' mt={4}>
          <Button mr={2} type='submit'>Search</Button>
          <Button onClick={() => {
            reset()
          }}>Reset Filters</Button>
        </Box>
      </Box>
    </form>
  )
}

export default ClientSearch
