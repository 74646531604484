import { useEffect, useState } from 'react'
import { useForm } from 'react-hook-form'
import { Text, Box, FormControl, FormLabel, Button, Input, FormErrorMessage } from '@chakra-ui/react'
import Card from 'components/Card'
import { containerWidth } from 'styles'
import { useNavigate } from 'react-router-dom'
import Error from 'components/Error'
import useTalent from 'hooks/talent'

const TalentAddress = () => {
  const {
    handleSubmit,
    register,
    getValues,
    formState: { errors },
    reset
  } = useForm()
  const {talent, updateTalent} = useTalent()
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()
  const [apiError, setApiError] = useState('')

  const saveAddress = async () => {
    setIsSubmitting(true)
    try {
      const values = getValues()
      await updateTalent({ address: values })
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (talent) {
      reset(talent.address)
    }
  }, [talent])

  console.log('errors: ', errors);

  return (
    <Box width={containerWidth} mx='auto'>
      <Text textAlign='center' my={8}>
        If you change your address please inform us at: <a href='mailto:accounting@palomamodelandtalent.com'>accounting@palomamodelandtalent.com</a>. We will also need an updated W9. Thanks!
      </Text>
      <Card title='Address' >
        <form onSubmit={handleSubmit(saveAddress)}>
          <Box>
            <FormControl  isInvalid={errors.city}>
              <FormLabel>City *</FormLabel>
              <Input {...register('city', { required: 'This field is required' })} />
              <FormErrorMessage>{(errors.city && errors.city.message)}</FormErrorMessage>
            </FormControl>
            <FormControl  isInvalid={errors.state}>
              <FormLabel>State *</FormLabel>
              <Input {...register('state', { required: 'This field is required' })} />
              <FormErrorMessage>{(errors.state && errors.state.message)}</FormErrorMessage>
            </FormControl>
            <FormControl  isInvalid={errors.zip}>
              <FormLabel>Zip *</FormLabel>
              <Input {...register('zip', { required: 'This field is required' })} />
              <FormErrorMessage>{(errors.zip && errors.zip.message)}</FormErrorMessage>
            </FormControl>
            <Error error={apiError} />
            <Box mt={5} display='flex' justifyContent='center'>
              <Button disabled={isSubmitting} isLoading={isSubmitting} mr={2} type='submit'>Save</Button>
              <Button disabled={isSubmitting} onClick={() => {
                navigate('/talent/dashboard')
              }}>Cancel</Button>
            </Box>
          </Box>
        </form>
      </Card>
    </Box>
  )
}

export default TalentAddress
