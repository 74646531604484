import React, { useState, useEffect } from 'react'
import { Link, useSearchParams } from 'react-router-dom'
import { resetPasswordRequest } from 'services'
import { Input, Button, Text, Box, Image } from '@chakra-ui/react'
import { useNavigate } from 'react-router-dom'
import PalomaLogo from 'asset/logo.jpg'
import useUIContext from 'hooks/ui-context'

const ResetPasswordRequest = (props) => {
  const [username, setUsername] = useState('')
  const [error, setError] = useState('')
  const {addNotification } = useUIContext()
  const navigate = useNavigate()
  const [searchParams] = useSearchParams()

  useEffect(() => {
    document.title = `Check In | Reset password request`;
    const initialUsername = searchParams.get('username')
    if (initialUsername) {
      setUsername(initialUsername)
    }
  }, [])

  const sendRequest = async (username) => {
    try {
      const res = await resetPasswordRequest(username, searchParams.get('resetUsername'))
      if (res.data.success) {
        addNotification({
          status: 'success',
          content: 'Successfully sent reset link. Please check you email for the reset link!'
        })
        // navigate(`/`)
      } else {
        setError(res.error)
      }
    } catch (error) {
      setError(error)
    }
  }

  return (
    <Box alignItems='center' justifyContent='center' height='100vh' display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='column'>
        <Image
          width={400}
          src={PalomaLogo}
          float='left'
          mb={5}
        />
        <Text fontSize={22}> Reset Password.</Text>
        <Box my={5}>
          <Text htmlFor="email">Username</Text>
          <Input
            type="email"
            id="email"
            aria-describedby="emailHelp"
            value={username}
            onChange={ev => setUsername(ev.target.value)}
          />
        </Box>
        <Text color='red'>{`${error || ''}`}</Text>
        <Box justifyContent='space-between' display='flex' alignItems='center'>
          <Link to='/auth/login'>
            Back to Login
          </Link>
          <Button
            type="submit"
            ml={16}
            className="btn btn-danger"
            onClick={() => sendRequest(username)}
          >Send Reset Request</Button>
        </Box>
      </Box>
    </Box>
  )
}

export default ResetPasswordRequest
