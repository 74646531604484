import { useEffect, useState } from 'react'
import { Box, Button, Table, TableContainer, Tbody, Td, Th, Thead, Tr, Text } from '@chakra-ui/react'
import { containerWidth } from 'styles'
import ClientSearch from 'components/ClientSearch'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import useUIContext from 'hooks/ui-context'
import { setCurrentSearch } from 'store/modules/search'
import LoadingBar from 'components/LoadingBar'
import Card from 'components/Card'
import useSearch from 'hooks/search'
import { FaPen, FaTrash, FaChevronUp, FaChevronDown } from 'react-icons/fa'
import Pagination from 'components/Pagination'
import { formatDate } from "utils";

const ORDER_BY_LIST = {
  dateran: 'date_ran',
}

const ORDER_TYPE = {
  desc: 'desc',
  asc: 'asc'
}

const ClientDashboard = () => {
  const user = useSelector(state => state.user.currentUser)
  const {listSearches, searches, deleteSearch} = useSearch()
  const { setBreadcrumbs, showConfirmModal, addNotification } = useUIContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [loading, setLoading] = useState(false)
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    totalPages: 1
  })
  const [orderBy, setOrderBy] = useState(ORDER_BY_LIST.dateran);
  const [orderType, setOrderType] = useState(ORDER_TYPE.desc);
  const loadSearches = async () => {
    setLoading(true)
    try {
      await listSearches({
        page_num: pagination.page,
        per_page: pagination.perPage,
        order_by: !!orderBy ? orderBy : 'date_ran',
        order_type: !!orderType ? orderType : 'desc'
      })
    } catch (err) {
      addNotification({
        status: 'error',
        content: `Failed to load Searches. ${err}`
      })
    }
    setLoading(false)
  }
  useEffect(() => {
    loadSearches()
    setBreadcrumbs([{
      path: '/client/dashboard',
      label: 'Dashboard'
    }, {
      path: '/client/dashboard/searches',
      label: 'Searches'
    }])
  }, [])

  useEffect(() => {
    loadSearches();
  }, [pagination.page, pagination.perPage]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalPages: Math.ceil(searches?.total_count / searches?.per_page),
    });
  }, [searches]);

  const deleteConfirmHandle = async (action, searchId) => {
    if (action !== 'yes') { return }
    await deleteSearch(searchId)
    await loadSearches()
  }

  const handleSortby = (name) => () => {
    setOrderBy(name);
    if(orderBy === name) {
      switch (orderType) {
        case ORDER_TYPE.desc:
          setOrderType(ORDER_TYPE.asc)
          break;
        case ORDER_TYPE.asc:
          setOrderType(ORDER_TYPE.desc)
          break;
        default:
          setOrderType(ORDER_TYPE.desc)
          break;
      }
    } else {
      setOrderType(ORDER_TYPE.desc)
    }
  }

  useEffect(() => {
    if(!!orderBy && !!orderType) {
      loadSearches()
    }
  }, [orderBy, orderType])

   const renderSortIcon = (name) => {
    if(orderBy === name) {
      if(orderType === ORDER_TYPE.desc) {
        return <FaChevronUp />
      }
      if(orderType === ORDER_TYPE.asc) {
        return <FaChevronDown />
      }
    }
    return null
  }

  return (
    <Box
      display='grid'
      gridTemplateColumns={{ base: '1fr', xl: '1fr 1fr'}}
      width={containerWidth}
      mx='auto'
      mb={12}
      gap={3}
    >
      <ClientSearch
        searchSubmit={search => {
          console.log('search: ', search);
          dispatch(setCurrentSearch({
            search,
            name: '',
            selected_talents: [],
            owner: user._id
          }))
          navigate('/client/dashboard/search/new')
        }}
      />
      <Box minH={0}>
        <LoadingBar loading={loading} />
        <Card title='Searches'>
          <TableContainer mb={3}>
            <LoadingBar loading={loading} />
            <Table>
              <Thead>
                <Tr>
                  <Th _hover={{cursor: 'pointer'}} onClick={handleSortby(ORDER_BY_LIST.dateran)}>
                    <Box display='flex'>
                      Date Ran
                      <Text ml={2}>
                        {renderSortIcon(ORDER_BY_LIST.dateran)}
                      </Text>
                    </Box>
                  </Th>
                  <Th>Client</Th>
                  <Th>Search Name</Th>
                  <Th>Action</Th>
                </Tr>
              </Thead>
              <Tbody>
                {searches?.searches?.map(search => {
                  return (
                    <Tr key={search._id}>
                      <Td>
                        {search.date_ran ? formatDate(search.date_ran) : "-"}
                      </Td>
                      <Td>{search.owner && search.owner.company}</Td>
                      <Td>{search.name}</Td>
                      <Td>
                        <Button variant='text' size='xs' onClick={() => {
                          navigate(`/client/dashboard/search/${search._id}`)
                        }}>
                          <FaPen />
                        </Button>
                        <Button variant='text' size='xs' onClick={() => {
                          showConfirmModal({
                            title: 'Are you sure?',
                            content: 'You won\'t be able to revert this action!',
                            buttons: [{
                              label: 'Yes',
                              action: 'yes',
                              color: 'red'
                            }, {
                              label: 'Cancel',
                              action: 'cancel'
                            }],
                            payload: search._id,
                            callback: deleteConfirmHandle
                          })
                        }}>
                          <FaTrash />
                        </Button>
                      </Td>
                    </Tr>
                  )
                })}
              </Tbody>
            </Table>
          </TableContainer>
          <Pagination
            pagination={pagination}
            setPagination={setPagination}
            perPageCountIncludes={['1', '2', '10', '25', '50', '100']}
          />
        </Card>
      </Box>
    </Box>
  )
}

export default ClientDashboard
