const USER_TYPES = {
  ADMIN: 'admin',
  CLIENT: 'client',
  TALENT: 'talent',
}

const TALENT_STATE = {
  NEW: 'new',
  ACCEPTED: 'accepted',
  DECLINED: 'declined',
}

const API_URL = process.env.REACT_APP_API_HOST
console.log('API_URL: ', API_URL);

const MAX_FILE_SIZE = 10 * 1024 * 1024
const MIN_FILE_SIZE = 200 * 1024

const MAX_PHOTO_COUNT = 20
const MAX_PHOTO_SUBMISSION_COUNT = 10

const SUPER_ADMIN_USERNAMES = [ 'admin', 'palomajackson5er', 'palomajackson5er' ]

export {
  API_URL,
  USER_TYPES,
  TALENT_STATE,
  MIN_FILE_SIZE,
  MAX_FILE_SIZE,
  MAX_PHOTO_COUNT,
  MAX_PHOTO_SUBMISSION_COUNT,
  SUPER_ADMIN_USERNAMES
}
