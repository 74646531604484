import { useState, useEffect } from "react";
import {
  Button,
  Input,
  Image,
  Text,
  FormControl,
  FormLabel,
  Modal,
  ModalOverlay,
  ModalContent,
  useDisclosure,
  FormErrorMessage,
  Box,
  Textarea,
  Checkbox,
  FormHelperText,
  useSafeLayoutEffect,
  Badge,
} from "@chakra-ui/react";
import { useSelector } from "react-redux";
import { useForm } from "react-hook-form";
import { colors } from "styles";
import { talentOnboard } from "services";
import Select from "components/Select";
import Error from "components/Error";
import Card from "components/Card";
import DateInput from "components/DateInput";
import TalentSocialFormSection from "components/TalentSocialFormSection";
import PalomaLogo from "asset/logo.jpg";
import { FaTimes } from "react-icons/fa";
import { uploadNewTalentPhotos } from "services/talent";
import { sizeDisplay, validateYear } from "utils";
import {
  MIN_FILE_SIZE,
  MAX_FILE_SIZE,
  MAX_PHOTO_SUBMISSION_COUNT,
} from "constants";
import useStyle from 'hooks/style.'
import heic2any from 'heic2any';

const ageGroupInstruction = [
  `Thank you for considering Paloma Model & Talent for representation. We are thrilled to be running our own agency and have the opportunity to provide children and their parents with dedicated, responsive, considerate, experienced, and protective assistance in the entertainment industry. Paloma Model & Talent is family owned and operated. We are hard-working sisters (just ask our husbands) with unparalleled drive, creativity, intelligence, industry knowledge, confidence, passion, spirit and gumption. But enough about us...

  Please submit recent outdoor pictures that are no more than a week old. If your child is older 6 months to 11 months, they must be sitting up in photos and if 12 months or older, standing. Please no photos with toys or food, and nothing in high chairs, strollers, or car seats. Keep hair natural with no hair accessories like bows, headbands, or hats. Photos must reflect how they look currently. We would like to see at least one smiling shot, a non-expression shot and a full-length body shot.

  You will get an automated response back once the submission has been successful. We will get back to you within two weeks by email so please add submissions@palomamodelandtalent.com and info@palomamodelandtalent.com to your address book to ensure the emails do not end up in spam. Thank you again for visiting us and taking this next step in making dreams come true for us all.`,

  `Thank you for considering Paloma Model & Talent for representation. We are grateful to have the opportunity to provide children and their parents with dedicated, responsive, considerate, experienced, and protective assistance in the entertainment industry.  We are hard-working sisters (just ask our husbands) and staff with unparalleled drive, creativity, intelligence, industry knowledge, confidence, passion, spirit and gumption. But enough about us…

  For our teen department, we are focused primarily on actors unless your teen still wears a kids size 12 or smaller, so please include links to their acting reels and samples. Please also upload a screenshot of their acting resume, or include their Actors Access or IMDb link in our links section. Feel free to add copious notes regarding their acting training and experience. Please submit recent pictures that reflect how they look now. We prefer to see current snapshots than older professional headshots, but please no shots in sunglasses or anything else that distracts from them. Please include a smiling shot showing teeth and a full-length shot, and crop photos so they fill the frame before uploading.

  You will get an automated email response once the submission has been successfully completed; please add submissions@palomamodelandtalent.com to your address book to ensure the email does not end up in spam. We will endeavor to get back to you within two weeks. Thank you again for visiting us and taking this next step in making dreams come true for us all.`,

  `You will get an automated response back once the submission has been successful.

  Currently, we are only accepting submissions from actors with established resumes and reel. Please include a link to these in your submission. Those seeking theatrical representation only must have co-star credits.
  
  We will get back to you within 30 days by email so please add submissions@palomamodelandtalent.com and info@palomamodelandtalent.com to your address book to ensure the emails do not end up in spam.`,
];

const defaultValues = {
  Babies: {
    address: {
      state: "CA",
    },
  },
  Adults: {
    is_over_25: null,
  },
};

const DetailForm = ({ ageGroup, back, postSubmit }) => {
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    watch,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      talent: {
        ...defaultValues[ageGroup[1]],
        is_homeschooled: false,
        other_abled: false,
        is_over_25: null,
      },
    },
  });
  const { errorColor } = useStyle()
  const [apiError, setApiError] = useState("");
  const [isSubmitting, setIsSubmitting] = useState(false);
  const enumData = useSelector((state) => state.talent.enumData);
  const { isOpen, onOpen: openConfirmModal, onClose } = useDisclosure();
  const [photos, setPhotos] = useState([]);

  const submitData = async () => {
    setIsSubmitting(true);
    try {
      const formData = new FormData();
      for (let i = 0; i < photos.length; i++) {
        if (
          photos[i].size < MAX_FILE_SIZE &&
          photos[i].size > MIN_FILE_SIZE
        ) {
          formData.append("files", photos[i]);
        }
      }
      const responseInPhotoUpload = await uploadNewTalentPhotos(formData);
       
      if (responseInPhotoUpload.status >= 500 && responseInPhotoUpload.status < 600) {
        setApiError('“Error! Your images have failed to upload. Please try again. If this error persists, please try a different browser or restarting your computer”');
        onClose();
        setIsSubmitting(false);
        return;
      }

      if (Array.isArray(responseInPhotoUpload.data) && responseInPhotoUpload.data.length === 0) {
        setApiError('“Error! Your images have failed to upload. Please try again. If this error persists, please try a different browser or restarting your computer”');
        onClose();
        setIsSubmitting(false);
        return;
      }
      const values = getValues();
      const res = await talentOnboard({...values.talent, photos: responseInPhotoUpload.data }, values.contact);
      postSubmit(res.data);
    } catch (err) {
      setApiError('“Error! Your images have failed to upload. Please try again. If this error persists, please try a different browser or restarting your computer”');
    }
    onClose();
    setIsSubmitting(false);
  };

  if (!enumData) {
    return;
  }

  const badPhotoCount = photos.filter(
    (photo) => photo.size > MAX_FILE_SIZE || photo.size < MIN_FILE_SIZE
  ).length;

  const isYoungAdult = ageGroup[0] === 2;

  console.log("control: ", control);
  console.log("errors: ", errors);
  return (
    <Box>
      <Card title={`Submission for ${ageGroup[1]}`}>
        <Box m={4}>
          {!isYoungAdult && (
            <Image
              width={200}
              display="inline"
              src={PalomaLogo}
              mr={3}
              float="left"
            />
          )}
          <Text display="inline" whiteSpace="pre-line">
            {ageGroupInstruction[ageGroup[0]]}
          </Text>
          {ageGroup[1] !== "Babies" && (
            <Text>
              We currently cannot consider talent that lives outside the U.S.
            </Text>
          )}
        </Box>
      </Card>
      <Card title="Photos" containerProps={{ my: 7 }}>
        <Box display="flex" alignItems="end">
          <Input
            multiple
            type="file"
            id="new-photo-upload"
            name="files"
            accept="image/png, image/gif, image/jpeg, image/heic, image/heif, .heic"
            {...register("files", {
              // required: "Please choose at least one file",
              validate: (v) => photos.length > 0 ? true : "Please choose at least one file",
            })}
            disabled={isSubmitting}
            onChange={async (ev) => {
              const files = Array.from(ev.target.files);
              const imageFiles = await Promise.all(files.map(async (file) => {
                if (file.type === 'image/heic' || file.type === 'image/heif' ||file.name.endsWith('.heic')) {
                  try {
                    const jpegBlob = await heic2any({
                      blob: file,
                      toType: 'image/jpeg',
                    });
                    return new File([jpegBlob], file.name.replace(/\.heic$/, '.jpg'));
                  } catch (error) {
                    console.error('HEIC conversion error:', error);
                    return null;
                  }
                } else {
                  return file;
                }
              }));
              const validImageFiles = imageFiles.filter((file) => file !== null);
              setPhotos(validImageFiles.concat(photos).slice(0, MAX_PHOTO_SUBMISSION_COUNT));  

              // setPhotos(
              //   photos
              //     .concat(Array.from(ev.target.files))
              //     .slice(0, MAX_PHOTO_SUBMISSION_COUNT)
              // );
            }}
            style={{ display: "none" }}
          />
          <Button
            disabled={isSubmitting}
            isLoading={isSubmitting}
            onClick={() => {
              document.querySelector("#new-photo-upload").click();
            }}
          >
            Upload Photos
          </Button>
        </Box>
        <Text my={3}>
          Upload up to {MAX_PHOTO_SUBMISSION_COUNT} JPG photos between{" "}
          {sizeDisplay(MIN_FILE_SIZE)} ~ {sizeDisplay(MAX_FILE_SIZE)} in size.
          Please only upload jpg, png, or heic photos. You must upload at least
          one photo.
        </Text>
        <Text color="red.500">{errors.files && errors.files.message}</Text>
        <Box
          display="grid"
          gridTemplateColumns={{
            base: "1fr",
            lg: "1fr 1fr",
            xl: "repeat(4, 1fr)",
          }}
          gridGap={3}
        >
          {photos.map((photo, idx) => {
            return (
              <Box
                key={`${idx}-${photo.name}`}
                position="relative"
                borderColor={
                  photo.size > MAX_FILE_SIZE || photo.size < MIN_FILE_SIZE
                    ? "red"
                    : "gray.400"
                }
                borderWidth={2}
                h={60}
              >
                <Image
                  opacity={
                    photo.size > MAX_FILE_SIZE || photo.size < MIN_FILE_SIZE
                      ? 0.3
                      : 1
                  }
                  w="full"
                  h="full"
                  objectFit="contain"
                  src={URL.createObjectURL(photo)}
                />
                <Box
                  position="absolute"
                  bottom={0}
                  bgColor="whiteAlpha.700"
                  px={2}
                >
                  <Text
                    color={
                      photo.size > MAX_FILE_SIZE || photo.size < MIN_FILE_SIZE
                        ? "red"
                        : "black"
                    }
                  >
                    {sizeDisplay(photo.size)}
                  </Text>
                </Box>
                <Badge
                  variant="solid"
                  position="absolute"
                  left={1}
                  top={0}
                  colorScheme="green"
                  borderRadius={3}
                  fontSize={16}
                >
                  {idx + 1}
                </Badge>
                <Badge
                  variant="outline"
                  position="absolute"
                  bg={"red.100"}
                  right={1}
                  top={0}
                  colorScheme="red"
                  borderRadius={3}
                  fontSize={16}
                  py={1}
                  cursor="pointer"
                  onClick={() => {
                    const updatedPhotos = photos.slice(0);
                    updatedPhotos.splice(idx, 1);
                    setPhotos(updatedPhotos);
                  }}
                >
                  <FaTimes />
                </Badge>
              </Box>
            );
          })}
        </Box>
        {badPhotoCount > 0 && (
          <Text color="red" mt={2}>
            {badPhotoCount} photos don't meet size restriction
          </Text>
        )}
      </Card>
      <Card title="Talent Details">
        <Text mb={5}>
          Please note we receive dozens of submissions every day. your patience
          with our review process is appreciated. We are constantly looking for
          new talent, but if you live outside of the Southern California area
          and are not a strong actor with credits and a reel, we are typically
          not able to offer representation at this time. Our adult department is
          focused on experienced actors at this time, not those looking solely
          for modeling work.
        </Text>
        <form onSubmit={handleSubmit(openConfirmModal)}>
          <Box
            display="grid"
            gridTemplateColumns={{
              base: "1fr",
              lg: "1fr 1fr",
              xl: "repeat(4, 1fr)",
            }}
            mt={3}
            gridGap={3}
          >
            <FormControl isInvalid={errors.talent && errors.talent.first_name}>
              <FormLabel>First Name *</FormLabel>
              <Input
                {...register("talent.first_name", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.first_name &&
                  errors.talent.first_name.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.last_name}>
              <FormLabel>Last Name *</FormLabel>
              <Input
                {...register("talent.last_name", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.last_name &&
                  errors.talent.last_name.message}
              </FormErrorMessage>
            </FormControl>
            {/* <FormControl>
              <FormLabel>Stage First Name</FormLabel>
              <Input {...register('talent.stage_first_name')}/>
            </FormControl>
            <FormControl>
              <FormLabel>Stage Last Name</FormLabel>
              <Input {...register('talent.stage_last_name')}/>
            </FormControl> */}

            <FormControl
              isInvalid={errors.talent && errors.talent.email}
              gridColumnStart="1"
            >
              <FormLabel>Email *</FormLabel>
              <Input
                type="email"
                {...register("talent.email", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.email &&
                  errors.talent.email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={errors.talent && errors.talent.confirm_email}
            >
              <FormLabel>Confirm Email *</FormLabel>
              <Input
                type="email"
                {...register("talent.confirm_email", {
                  required: "This field is required",
                  validate: (v) =>
                    watch("talent.email") === v
                      ? true
                      : "Confirm email doesn't match",
                })}
                onChange={(ev) => {
                  if (!isYoungAdult) {
                    setValue("contact.email", ev.target.value);
                  }
                }}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.confirm_email &&
                  errors.talent.confirm_email.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                errors.talent && errors.talent.phones && errors.talent.phones[0]
              }
            >
              <FormLabel>Phone (1) *</FormLabel>
              <Input
                {...register("talent.phones[0]", {
                  required: "This field is required",
                })}
                onChange={(ev) => {
                  if (!isYoungAdult) {
                    setValue("contact.cell_number", ev.target.value);
                  }
                }}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.phones &&
                  errors.talent.phones[0] &&
                  errors.talent.phones[0].message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Phone (2)</FormLabel>
              <Input {...register("talent.phones[1]")} />
            </FormControl>

            {/* <FormControl gridColumnStart='1'>
              <FormLabel>Street</FormLabel>
              <Input {...register('talent.address.street')} />
            </FormControl> */}
            <FormControl
              gridColumnStart="1"
              isInvalid={
                errors.talent &&
                errors.talent.address &&
                errors.talent.address.city
              }
            >
              <FormLabel>City *</FormLabel>
              <Input
                {...register("talent.address.city", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.address &&
                  errors.talent.address.city &&
                  errors.talent.address.city.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                errors.talent &&
                errors.talent.address &&
                errors.talent.address.state
              }
            >
              <FormLabel>State *</FormLabel>
              <Input
                readOnly={ageGroup[1] === "Babies"}
                {...register("talent.address.state", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.address &&
                  errors.talent.address.state &&
                  errors.talent.address.state.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              isInvalid={
                errors.talent &&
                errors.talent.address &&
                errors.talent.address.zip
              }
            >
              <FormLabel>Zip *</FormLabel>
              <Input
                {...register("talent.address.zip", {
                  required: "This field is required",
                })}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.address &&
                  errors.talent.address.zip &&
                  errors.talent.address.zip.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl
              isInvalid={errors.talent && errors.talent.cloth_size}
              gridColumnStart="1"
            >
              <FormLabel>Clothing Size *</FormLabel>
              <Select
                control={control}
                name="talent.cloth_size"
                rules={{ required: "This field is required" }}
                options={enumData.cloth_size}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.cloth_size &&
                  errors.talent.cloth_size.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.shoe_size}>
              <FormLabel>Shoe Size *</FormLabel>
              <Select
                control={control}
                name="talent.shoe_size"
                rules={{ required: "This field is required" }}
                options={enumData.shoe_size}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.shoe_size &&
                  errors.talent.shoe_size.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.talent && errors.talent.height}>
              <FormLabel>Height *</FormLabel>
              <Select
                control={control}
                name="talent.height"
                rules={{ required: "This field is required" }}
                options={enumData.height}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.height &&
                  errors.talent.height.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.weight}>
              <FormLabel>Weight *</FormLabel>
              <Select
                control={control}
                name="talent.weight"
                rules={{ required: "This field is required" }}
                options={enumData.weight}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.weight &&
                  errors.talent.weight.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.eye_color}>
              <FormLabel>Eye Color *</FormLabel>
              <Select
                control={control}
                name="talent.eye_color"
                rules={{ required: "This field is required" }}
                options={enumData.eye_color}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.eye_color &&
                  errors.talent.eye_color.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.hair_color}>
              <FormLabel>Hair Color *</FormLabel>
              <Select
                control={control}
                name="talent.hair_color"
                rules={{ required: "This field is required" }}
                options={enumData.hair_color}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.hair_color &&
                  errors.talent.hair_color.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.talent && errors.talent.gender}>
              <FormLabel>Gender *</FormLabel>
              <Select
                control={control}
                isMulti
                name="talent.gender"
                rules={{ required: "This field is required" }}
                options={enumData.gender}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.gender &&
                  errors.talent.gender.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Identical Twin</FormLabel>
              <Select
                control={control}
                name="talent.is_twin"
                options={enumData.boolean_value}
              />
            </FormControl>
            {ageGroup[1] === "Adults" ? (
              <FormControl
                isInvalid={errors.talent && errors.talent.is_over_25}
              >
                <FormLabel>Is Over 25 ? *</FormLabel>
                <Select
                  control={control}
                  name="talent.is_over_25"
                  options={enumData.boolean_value}
                  rules={{
                    validate: (value, formValues) => value !== null
                    // required:
                    //   "You must select either over or under 25. We ask this to know if you are eligible to participate in alcohol advertising",
                  }}
                />
                <FormErrorMessage>
                  {errors.talent &&
                    errors.talent.is_over_25 &&
                    errors.talent.is_over_25.message}

                  {errors.talent &&
                    errors.talent.is_over_25 &&
                    errors.talent.is_over_25.type === 'validate' && "You must select either over or under 25. We ask this to know if you are eligible to participate in alcohol advertising"}
                </FormErrorMessage>
              </FormControl>
            ) : (
              <FormControl
                isInvalid={errors.talent && errors.talent.birth_date}
              >
                <FormLabel>Date of Birth *</FormLabel>
                <DateInput
                  control={control}
                  name="talent.birth_date"
                  rules={{ required: "This field is required", validate: validateYear }}
                />
                <FormErrorMessage>
                  {errors.talent &&
                    errors.talent.birth_date &&
                    errors.talent.birth_date.message}
                </FormErrorMessage>
              </FormControl>
            )}
            <FormControl>
              <FormLabel>Special Skills</FormLabel>
              <Select
                control={control}
                isMulti
                name="talent.special_skills"
                options={enumData.skills}
              />
            </FormControl>
            <FormControl>
              <FormLabel>Other abled?</FormLabel>
              <Select
                control={control}
                name="talent.other_abled"
                options={enumData.boolean_value}
              />
            </FormControl>

            <FormControl
              gridColumn={{ base: "", lg: "1 / span 2" }}
              isInvalid={errors.talent && errors.talent.ethnicity_actual}
            >
              <FormLabel>Ethnic Heritage (actual) *</FormLabel>
              <Select
                control={control}
                isMulti
                name="talent.ethnicity_actual"
                rules={{ required: "This field is required" }}
                options={enumData.ethnicity}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.ethnicity_actual &&
                  errors.talent.ethnicity_actual.message}
              </FormErrorMessage>
            </FormControl>
            <FormControl
              gridColumn={{ base: "", lg: "span 2" }}
              isInvalid={errors.talent && errors.talent.ethnicity_look}
            >
              <FormLabel>Racial Appearance *</FormLabel>
              <Select
                control={control}
                isMulti
                name="talent.ethnicity_look"
                rules={{ required: "This field is required" }}
                options={enumData.racial_appearance}
              />
              <FormErrorMessage>
                {errors.talent &&
                  errors.talent.ethnicity_look &&
                  errors.talent.ethnicity_look.message}
              </FormErrorMessage>
            </FormControl>

            <FormControl gridColumn="1 / -1">
              <FormLabel>Actual Ethnic Heritage Notes</FormLabel>
              <Textarea
                minHeight="unset"
                rows={3}
                {...register("talent.ethnicity_note")}
              />
              <FormHelperText>
                Please specify associated country. We often get very specific
                requests (e.g. - Korean, Guatemalan, Filipino)
              </FormHelperText>
            </FormControl>

            <Box
              gridColumn="1 / -1"
              display="grid"
              gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
              m={8}
              gap={3}
            >
              <Box>
                <Text textAlign="center" m={8} mb={2}>
                  Please enter your full profile URL for any PUBLIC accounts.
                  Please verify all links before
                  finalizing your submission. Do not add any links to private
                  accounts.
                </Text>
                <Text textAlign="center" m={8} mt={0}>
                  If you do not have any of these sites, please leave empty; do
                  not type N/A, etc. No need to add # of followers unless
                  significant.
                </Text>
                <TalentSocialFormSection
                  prefix="talent"
                  register={register}
                  setValue={setValue}
                />
              </Box>
              <Box>
                {!["Babies", "Adults"].includes(ageGroup[1]) && (
                  <FormControl>
                    <FormLabel>Is Homeschooled?</FormLabel>
                    <Select
                      control={control}
                      name="talent.is_homeschooled"
                      options={enumData.boolean_value}
                    />
                    <FormHelperText>
                      Does not include online schooling while enrolled in public
                      school.
                    </FormHelperText>
                  </FormControl>
                )}
                <Text textAlign="center" m={8}>
                  Are you or your child currently represented by any other
                  agency or manager? If so, please list them and the respective
                  division (all, theatrical, commercial, Etc):
                </Text>
                <FormControl gridColumnStart="1">
                  <FormLabel>Also represented by</FormLabel>
                  <Input {...register("talent.represented_by")} />
                </FormControl>
                <Text textAlign="center" m={8}>
                  Please let us know your preferred pronouns and if you have any
                  other special skills, especially if you speak any other
                  languages. We love seeing video introductions so if you want
                  to send us one, please also include a link to your video in
                  the box below. This is your opportunity to tell us more about
                  you!
                </Text>
                <FormControl>
                  <FormLabel>Message</FormLabel>
                  <Textarea {...register("talent.message")} />
                </FormControl>
              </Box>
            </Box>
            {!isYoungAdult && (
              <Box
                gridColumn="1 /-1"
                px={10}
                display="grid"
                gridTemplateColumns={window.innerWidth > 768 ? "1fr 1fr 1fr" : ''}
                gridGap={3}
              >
                <Text fontWeight="bold" mb={2}>
                  Submitted By
                </Text>
                <FormControl
                  gridColumnStart="1"
                  isInvalid={errors.contact && errors.contact.first_name}
                >
                  <FormLabel>First Name *</FormLabel>
                  <Input
                    {...register("contact.first_name", {
                      required: "This field is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.contact &&
                      errors.contact.first_name &&
                      errors.contact.first_name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.contact && errors.contact.last_name}
                >
                  <FormLabel>Last Name *</FormLabel>
                  <Input
                    {...register("contact.last_name", {
                      required: "This field is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.contact &&
                      errors.contact.last_name &&
                      errors.contact.last_name.message}
                  </FormErrorMessage>
                </FormControl>
                <FormControl
                  isInvalid={errors.contact && errors.contact.relationship}
                >
                  <FormLabel>Relationship *</FormLabel>
                  <Input
                    {...register("contact.relationship", {
                      required: "This field is required",
                    })}
                  />
                  <FormErrorMessage>
                    {errors.contact &&
                      errors.contact.relationship &&
                      errors.contact.relationship.message}
                  </FormErrorMessage>
                </FormControl>
                <Input hidden type="email" {...register("contact.email")} />
                <Input hidden {...register("contact.cell_number")} />
                <Input
                  hidden
                  {...register("contact.contact_type")}
                  value={enumData.contact_type[0].value}
                />
              </Box>
            )}

            <Error error={apiError} />
            <Box gridColumn="1 / -1" mt={4}>
              {
                errors && Object.keys(errors).length > 0 &&
                <Text mt={2} mb={2} color={errorColor}>Please fix required fields above</Text>
              }
              <Button mr={2} type="submit">
                Continue
              </Button>
              <Button onClick={back}>Back</Button>
            </Box>
          </Box>
        </form>
      </Card>

      <Modal isOpen={isOpen} size="xl">
        <ModalOverlay />
        <ModalContent>
          <Card title="Submit Talent">
            <Text mb={5}>
              By checking this box you signify that you have answered these
              questions honestly and are submitting yourself or are the manager
              or legal guardian for the talent being submitted.
              <br />
              By submitting, you are confirming that the submitted talent has a
              valid U.S. Social Security number and can work legally in the
              United States, unless the talent is a newborn and a social is in
              process.
              <br />
              <br />
              Please add <strong>
                submissions@palomamodelandtalent.com
              </strong>{" "}
              to your address book or safe sender list to ensure email delivery.
              <br />
              If you do not receive a confirmation email then your submission
              was not processed; please check your spam or junk mail before
              re-submitting.
              <br />
              <br />
              We endeavor to get back to you within 3 weeks but it may be longer
              depending on our needs for talent in your demographic.
              <br />
              Click submit only once. The process may take a while depending on
              the size of your photos being uploaded.
            </Text>
            <form onSubmit={handleSubmit(submitData)}>
              <Box display="flex" justifyContent="center">
                <FormControl isRequired>
                  <FormLabel>
                    <Checkbox
                      rules={{ required: "This field is required" }}
                      mr={2}
                      mt={1}
                    />
                    I agree to the terms
                  </FormLabel>
                  <FormErrorMessage>
                    {errors.user &&
                      errors.user.password &&
                      errors.user.password.message}
                  </FormErrorMessage>
                </FormControl>

                <Button
                  variant="ghost"
                  mr={3}
                  onClick={onClose}
                  disabled={isSubmitting}
                >
                  Cancel
                </Button>
                <Button
                  type="submit"
                  disabled={isSubmitting}
                  isLoading={isSubmitting}
                >
                  Submit
                </Button>
              </Box>
            </form>
          </Card>
        </ModalContent>
      </Modal>
    </Box>
  );
};

export default DetailForm;
