import { Box } from '@chakra-ui/react'
import React from 'react'
import { FaSort, FaSortDown, FaSortUp } from 'react-icons/fa'

const Sorter = ({ value = null, setValue }) => {
  return (
    <Box cursor='pointer' display='flex' flexDirection='column' onClick={() => {
      switch (value) {
        case null:
          setValue(1)
          break
        case 1:
          setValue(-1)
          break
        case -1:
          setValue(null)
          break
        default:
          setValue(null)
      }
    }}>
      {value === 1 ?
        <FaSortUp />
      : value === -1 ?
        <FaSortDown />
      : <FaSort opacity={.3} />
      }
    </Box>
  )
}

export default Sorter
