import { Box, Button, FormControl, FormLabel, Image, Input } from '@chakra-ui/react'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { FaUserCircle } from 'react-icons/fa'
import { useForm } from 'react-hook-form'
import { API_URL } from 'constants'
import Card from 'components/Card'
import { containerWidth } from 'styles'
import { uploadFiles, updateUser, getLoggedInUser } from 'services'
import { setCurrentUser } from 'store/modules/user'

const UserProfile = () => {
  const user = useSelector(state => state.user.currentUser)
  const [logoSubmitting, setLogoSubmitting] = useState(false)
  const [apiError, setApiError] = useState('')
  const [loading, setLoading] = useState(false)
  const logoForm = useRef(null)
  const dispatch = useDispatch()
  const {
    handleSubmit,
    register,
    reset,
    formState: { errors },
    getValues
  } = useForm()

  useEffect(() => {
    reset(user)
  }, [user])

  const refreshUser = async () => {
    const userRes = await getLoggedInUser()
    dispatch(setCurrentUser(userRes.data))
  }

  const saveProfile = async () => {
    setLoading(true)
    try {
      const fields = getValues()
      await updateUser(user._id, fields)
      await refreshUser()
    } catch (err) {
      setApiError(`${err}`)
    }
    setLoading(false)
  }

  const handleUpload = async () => {
    setLogoSubmitting(true)
    try {
      const formData = new FormData(logoForm.current)
      const res = await uploadFiles(formData)
      await updateUser(user._id, {
        logo: res.data[0]
      })
      await refreshUser()
    } catch (err) {
      setApiError(`${err}`)
    }
    setLogoSubmitting(false)
  }

  return (
    <Box width={containerWidth} mx='auto'>
      <Card title='Profile' >
        <form ref={logoForm}>
          <Box display='flex' alignItems='end'>
            {user.logo ? (
              <Image
                width={150}
                borderRadius={5}
                overflow='hidden'
                src={`${API_URL}/s3/${encodeURIComponent(user.logo)}`}
              />
            ): (
              <FaUserCircle  size={150}/>
            )}
            <input
              type='file'
              id="new-logo-upload"
              name='files'
              accept='image/png, image/gif, image/jpeg'
              {...register("files", { required: 'Please choose at least one file'})}
              disabled={logoSubmitting}
              onChange={() => {
                handleUpload()
              }}
              style={{ display: 'none' }}
            />
            <Button
              disabled={logoSubmitting}
              isLoading={logoSubmitting}
              ml={3}
              onClick={() => {
                document.querySelector('#new-logo-upload').click()
              }}
            >Upload New Logo</Button>
          </Box>
        </form>
        <form onSubmit={handleSubmit(saveProfile)}>
          <Box display='flex' gap={3} mt={5}>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input {...register('first_name')} />
            </FormControl>
            <FormControl>
              <FormLabel>Last Name</FormLabel>
              <Input {...register('last_name')} />
            </FormControl>
          </Box>
          <Button mt={3} type='submit' loading={loading}>Save</Button>
        </form>
      </Card>
    </Box>
  )
}

export default UserProfile
