import { configureStore } from '@reduxjs/toolkit'
import { persistStore, persistReducer } from 'redux-persist'
import { combineReducers } from 'redux'
import storage from 'redux-persist/lib/storage'

import appReducer from './modules/app'
import talentReducer from './modules/talent'
import userReducer from './modules/user'
import searchReducer from './modules/search'

const persistConfig = {
  key: 'root',
  storage,
}

const persistedReducer = persistReducer(persistConfig, combineReducers({
  app: appReducer,
  talent: talentReducer,
  user: userReducer,
  search: searchReducer
}))

const store = configureStore({
  reducer: persistedReducer,
  middleware: gd => gd({ serializableCheck: false })
})

export const persistor = persistStore(store)

export const clearPersistedState = () => {
  console.log('Hello')
  persistor.flush();
  localStorage.removeItem('persist:root')
};

export default store
