import { useEffect, useState } from "react";
import { Select } from "chakra-react-select";
import { searchClients } from "services";
import { useController } from "react-hook-form";

const ClientSelect = ({ control, name, rules, isMulti }) => {
  const [searchText, setSearchText] = useState("");
  const [page, setPage] = useState(0);
  const [clients, setClients] = useState([]);
  const [selectedValue, setSelected] = useState([]);
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules,
  });

  // console.log("control: ", control);

  const getClients = async () => {
    const res = await searchClients({
      page_num: page,
      per_page: 30,
      contact: searchText,
    });
    const users = res.data.users;
    users.sort((a, b) => a.contact.localeCompare(b.contact));
    setClients(users);
  };

  useEffect(() => {
    setPage(0);
    getClients();
  }, [searchText]);

  useEffect(() => {
    getClients();
  }, []);

  const options =
    (clients.length > 0 &&
      clients.map((client) => ({
        label: `${client.contact} (${client.email})`,
        value: client._id,
      }))) ||
    [];

  // let parsedValue = options.filter((item) => {
  //   return isMulti ? (value || []).includes(item.value) : item.value === value;
  // });
  // if (!isMulti) {
  //   console.log("parsedValue is Multi: ", parsedValue)
  //   parsedValue = parsedValue[0] || null;
  // }
  // console.log("parsedValue: ", parsedValue, value, options);
  return (
    <Select
      value={selectedValue}
      onInputChange={(ev) => {
        setSearchText(ev);
      }}
      placeholder="Select Client"
      onBlur={onBlur}
      options={options}
      onChange={(ev) => {
        setSelected(isMulti ? ev.map((item) => item) : ev);
        onChange(isMulti ? ev.map((item) => item.value) : ev.value);
      }}
      onMenuScrollToBottom={() => {
        setPage(page + 1);
      }}
    />
  );
};

export default ClientSelect;
