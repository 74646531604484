import { useContext } from 'react'
import { UIContext } from 'UIProvider'

const useUIContext = () => {
  const uiContext = useContext(UIContext)

  return { ...uiContext.actions, ...uiContext.state }
}

export default useUIContext
