import axiosInstance from './service'

const listSearches = async (search) => {
  const res = await axiosInstance.post(`/search/list`, search)
  return res
}

const createSearch = async (fields) => {
  const res = await axiosInstance.post(`/search/`, { search: fields })
  return res
}

const updateSearch = async (searchId, fields) => {
  const res = await axiosInstance.put(`/search/${searchId}`, { search: fields })
  return res
}

const deleteSearch = async (searchId) => {
  const res = await axiosInstance.delete(`/search/${searchId}`)
  return res
}

const getOneSearch = async (searchId) => {
  const res = await axiosInstance.get(`/search/${searchId}`)
  return res
}

const listTalentsBySearch = async (search) => {
  const res = await axiosInstance.post('/search/search-talents', {search})
  return res
}

export {
  listSearches,
  createSearch,
  updateSearch,
  deleteSearch,
  getOneSearch,
  listTalentsBySearch
}
