import React from 'react';
import ReactDOM from 'react-dom/client';
import { ChakraProvider, extendTheme, defineStyleConfig } from '@chakra-ui/react'
import { Provider } from 'react-redux'
import { BrowserRouter } from 'react-router-dom'
import { PersistGate } from 'redux-persist/integration/react'
import App from './App';
import reportWebVitals from './reportWebVitals'
import UIProvider from 'UIProvider'
import store, { persistor } from './store'
import { colors } from 'styles'

import ConfirmModal from 'components/ConfirmModal'
import RootLoading from 'components/RootLoading'
import Notifications from 'components/Notifications'
import ScrollToTop from 'components/ScrollToTop'
import './index.css';

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <Provider store={store}>
      <PersistGate loading={null} persistor={persistor}>
        <UIProvider>
          <ChakraProvider theme={extendTheme({
            components: {
              Button: defineStyleConfig({
                defaultProps: {
                  bg: colors.doubleDark,
                  bgColor: colors.doubleDark
                },
              })
            }
          })}>
            <BrowserRouter>
              <App />
              
              <ScrollToTop />
              <ConfirmModal />
              <RootLoading />
              <Notifications />
            </BrowserRouter>
          </ChakraProvider>
        </UIProvider>
      </PersistGate>
    </Provider>
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
