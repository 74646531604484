import React from 'react'
import { Select } from 'chakra-react-select'
import { useController } from 'react-hook-form'


const ControlledSelect = ({ control, name, label, rules, ...props }) => {
  const {
    field: { onChange, onBlur, value, ref },
  } = useController({
    name,
    control,
    rules
  });

  const { isMulti } = props

  let parsedValue = props.options.filter(item => {
    return isMulti ? (value || []).includes(item.value) : item.value === value
  })
  if (!props.isMulti) { parsedValue = parsedValue[0] || null }

  return (
    <Select
      name={name}
      ref={ref}
      onChange={ev => {
        if (!ev) {
          onChange(ev)
        } else {
          onChange(isMulti ? ev.map(item => item.value) : ev.value)
        }
      }}
      onBlur={onBlur}
      value={parsedValue}
      {...props}
    />
  );
};

export default ControlledSelect
