import { useEffect, useState } from "react";
import {
  Button,
  Collapse,
  Checkbox,
  Box,
  Table,
  Thead,
  Tbody,
  TableContainer,
  Tr,
  Td,
  Th,
  FormControl,
  FormLabel,
  Input,
  HStack, 
  Text 
} from "@chakra-ui/react";
import useTalent from "hooks/talent";
import useUIContext from "hooks/ui-context";
import { containerWidth } from "styles";
import { FaCaretDown, FaCaretUp } from "react-icons/fa";
import Card from "components/Card";
import { colors } from "styles";
import LoadingBar from "components/LoadingBar";
import { updateTalent } from "services/talent";
import Pagination from "components/Pagination";
import Sorter from 'components/Sorter'

const Affiliation = () => {
  const [loading, setLoading] = useState(false);
  const { talents, searchTalents } = useTalent();
  const [showFilter, setShowFilter] = useState(false);
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 100,
    totalPages: 1,
  });
  const [filter, setFilter] = useState({
    first_name: "",
    last_name: "",
  });
  const [sort, setSort] = useState({
    last_name: 1,
  })
  const { setBreadcrumbs } = useUIContext();

  const loadData = async () => {
    setLoading(true);
    const { page, perPage } = pagination;
    await searchTalents({
      search: {
        ...filter,
        is_active: true,
        is_accepted: true,
        page_num: page,
        per_page: perPage,
        sort: sort,
      },
    });
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [filter, pagination.page, pagination.perPage, sort]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalPages: Math.ceil(talents.total_count / talents.per_page),
    });
  }, [talents]);

  useEffect(() => {
    setBreadcrumbs([
      {
        path: "/admin/dashboard",
        label: "Dashboard",
      },
      {
        path: "/admin/dashboard/talents",
        label: "Talent",
      },
    ]);
  }, []);

  const updateTalentFlag = async (talent, flag, value) => {
    setLoading(true);
    await updateTalent(talent._id, {
      flags: { ...talent.flags, [flag]: value },
    });
    await loadData();
    setLoading(false);
  };

  return (
    <Box width={containerWidth} mx="auto">
      <Box mb={3}>
        <Button
          w="100%"
          justifyContent="space-between"
          onClick={() => setShowFilter(!showFilter)}
          rightIcon={!showFilter ? <FaCaretDown /> : <FaCaretUp />}
        >
          filters
        </Button>
        <Collapse in={showFilter}>
          <Box
            px={5}
            pt={2}
            pb={5}
            borderWidth={1}
            borderTopWidth={0}
            borderColor={colors.dark}
            display="grid"
            gridTemplateColumns={{ base: "1fr", md: "1fr 1fr" }}
            gap={3}
          >
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                value={filter.first_name}
                onChange={(ev) => {
                  setFilter({ ...filter, first_name: ev.target.value });
                }}
              />
            </FormControl>
            <FormControl>
              <FormLabel>First Name</FormLabel>
              <Input
                value={filter.last_name}
                onChange={(ev) => {
                  setFilter({ ...filter, last_name: ev.target.value });
                }}
              />
            </FormControl>
            <Box gridColumn="1 / -1">
              <Button
                onClick={() => {
                  setFilter({ first_name: "", last_name: "" });
                }}
              >
                Reset Filters
              </Button>
            </Box>
          </Box>
        </Collapse>
      </Box>

      <Card title="Talent Affiliations">
        <LoadingBar loading={loading} />
        <TableContainer mb={3}>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <HStack>
                    <Text>Name</Text>
                    <Sorter value={sort.first_name} setValue={(v) => {
                      setSort({ ...sort, first_name: v })
                    }} />
                  </HStack>
                </Th>
                <Th>LAC</Th>
                <Th>AA</Th>
                <Th>CF</Th>
                <Th>W9</Th>
                <Th>CA</Th>
                <Th>WP</Th>
                <Th>ND</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(talents.talents || []).map((talent) => {
                return (
                  <Tr key={talent._id}>
                    <Td>
                      {talent.first_name} {talent.last_name}
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={talent.flags && talent.flags.lac}
                        onChange={(ev) => {
                          updateTalentFlag(talent, "lac", ev.target.checked);
                        }}
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={talent.flags && talent.flags.aa}
                        onChange={(ev) => {
                          updateTalentFlag(talent, "aa", ev.target.checked);
                        }}
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={talent.flags && talent.flags.cf}
                        onChange={(ev) => {
                          updateTalentFlag(talent, "cf", ev.target.checked);
                        }}
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={talent.flags && talent.flags.w9}
                        onChange={(ev) => {
                          updateTalentFlag(talent, "w9", ev.target.checked);
                        }}
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={talent.flags && talent.flags.ca}
                        onChange={(ev) => {
                          updateTalentFlag(talent, "ca", ev.target.checked);
                        }}
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={talent.flags && talent.flags.wp}
                        onChange={(ev) => {
                          updateTalentFlag(talent, "wp", ev.target.checked);
                        }}
                      />
                    </Td>
                    <Td>
                      <Checkbox
                        isChecked={talent.flags && talent.flags.nd}
                        onChange={(ev) => {
                          updateTalentFlag(talent, "nd", ev.target.checked);
                        }}
                      />
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination pagination={pagination} setPagination={setPagination} perPageCountIncludes={['1', '2', '10', '25', '50', '100']} />
      </Card>
    </Box>
  );
};

export default Affiliation;
