import { memo } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate, useLocation } from 'react-router-dom'
import useAuthorization from 'hooks/use-authorization';
import { setToken } from 'store/modules/user'

const nonAuthRoutes = ['/auth', '/talent/submission', '/talent-choice', '/client/dashboard/search', '/talent/']

const AuthWrapper = memo(({ children }) => {
    const navigate = useNavigate();
    const dispatch = useDispatch();
    const location = useLocation()
    const { isAuthenticated } = useAuthorization();
    const requireLogin = !nonAuthRoutes.find(item => location.pathname.startsWith(item))

    if (isAuthenticated() || !requireLogin) {
        return children;
    } else {
        dispatch(setToken(null))
        navigate('/auth/login')
        return null;
    }
});

export default AuthWrapper;
