import { useState } from 'react'
import { Text, Box, Button } from '@chakra-ui/react'
import { Link, useNavigate } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import ChoiceSelect from './submission/ChoiceSelect'
import DetailForm from './submission/DetailForm'
import { containerWidth } from 'styles'
import { colors } from 'styles'
import { setToken, setCurrentUser } from 'store/modules/user'
import { getLoggedInUser } from 'services'
import useStyle from 'hooks/style.'

const steps = {
  ageGroup: 'age-group',
  detailForm: 'detail-form',
  thankyou: 'thankyou'
}

const SubmitForm = () => {
  const [step, setStep] = useState(steps.ageGroup)
  const [talent, setTalent] = useState(null)
  const [ageGroup, setAgeGruop] = useState([0, 'Babies'])
  const { darkColor } = useStyle()

  const handlePostSubmit = async (talent) => {
    setTalent(talent)
    setStep(steps.thankyou)
  }

  return (
    <Box display='flex' flexDirection='column' alignItems='center' width={containerWidth} mx='auto'>
      <Text mt={12} fontSize={22} mb={16} >Talent Submission</Text>
      <Box display={step === steps.ageGroup ? 'block': 'none'}>
        <ChoiceSelect setAgeGruop={group => {
            setAgeGruop(group)
            setStep(steps.detailForm)
          }} />
      </Box>
      <Box display={step === steps.detailForm ? 'block': 'none'} mb='44' width='100%'>
        <DetailForm
          key={ageGroup[1]}
          ageGroup={ageGroup}
          postSubmit={handlePostSubmit}
          back={() => { setStep(steps.ageGroup)}}
        />
      </Box>
      <Box display={step === steps.thankyou ? 'block': 'none'} mb='44'>
        {talent && (
          <Box display='flex' flexDir='column' alignItems='center' py='40'>
            <Text mb={5} fontSize='3xl'>Thank you for your submission!</Text>
            <Text mb={10} fontSize='xl'>We will notify you at {talent && talent.email} once we have reviewed your submission.</Text>
            <Button bgColor={darkColor} textColor={colors.primaryText} onClick={() => {
              window.location.href = 'https://palomamodelandtalent.com/'
            }}>
                Go Home
            </Button>
          </Box>
        )}
      </Box>
    </Box>
  )
}

export default SubmitForm
