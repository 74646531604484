import { useDispatch, useSelector } from 'react-redux'
import { deleteUserAndSet, registerUserAndSet,
  searchClientsAndSet, updateUserAndSet, searchAdminsAndSet, registerAdminUserAndSet } from 'store/modules/user'

const useUser = () => {
  const users = useSelector(state => state.user.users)
  const dispatch = useDispatch()

  const searchClients = async (search) => {
    return await searchClientsAndSet(search, dispatch)
  }

  const updateUser = async (id, fields) => {
    return await updateUserAndSet(id, fields, dispatch)
  }

  const registerUser = async (fields) => {
    return await registerUserAndSet(fields, dispatch)
  }

  const deleteUser = async (id) => {
    return await deleteUserAndSet(id, dispatch)
  }

  const searchAdmins = async (search) => {
    return await searchAdminsAndSet(search, dispatch)
  }

  const registerAdminUser = async (fields) => {
    return await registerAdminUserAndSet(fields, dispatch)
  }

  return {
    users,
    updateUser,
    deleteUser,
    registerUser,
    searchClients,
    searchAdmins,
    registerAdminUser
  }
}

export default useUser
