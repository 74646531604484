import React, { useRef, useState } from 'react';

const UIContext = React.createContext()

const UIProvider = ({ ...props }) => {
  const [confirmModal, setConfirmModal] = useState({
    show: false,
    title: '',
    content: '',
    buttons: [{
      label: 'Ok',
      action: 'ok'
    }],
  })
  const [breadcrumbs, setBreadcrumbs] = useState([])
  const [notifications, setNotifications] = useState([])

  const showConfirmModal = (payload) => {
    setConfirmModal({
      ...confirmModal,
      show: true,
      ...payload
    })
  }

  const addNotification = (notification) => {
    setNotifications([
      ...notifications,
      {
        id: Math.random(),
        timeout: 3000,
        ...notification
      }
    ])
  }

  return <UIContext.Provider value={{
    state: { confirmModal, breadcrumbs, notifications },
    actions: { showConfirmModal, setBreadcrumbs, addNotification, setNotifications }
  }} {...props} />
}

export {
  UIContext
}
export default UIProvider
