import axios from 'axios'
import { API_URL } from 'constants'

const instance = axios.create({
  baseURL: API_URL,
  // timeout: 1000
})

instance.interceptors.request.use(config => {
  const token = window.token || localStorage.getItem('token')
  if (token) { config.headers.Authorization = `Bearer ${token}` }
  else { delete config.headers.Authorization }
  return config
})


instance.interceptors.response.use(
  response => {
    return response;
  },
  error => {
    console.log('error', error)
    if (error.response && error.response.status === 401) {
      // Handle the 401 error here
      // For example, you can redirect to a login page or perform other actions
      console.log('Unauthorized error:', error);
      localStorage.clear()
      document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
      window.location.replace('/auth/login')
    }
    return error.response
  }
);

export default instance
