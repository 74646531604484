import { useColorModeValue } from '@chakra-ui/react'
import { colors } from 'styles'

const useStyle = () => {
  const darkColor = useColorModeValue(colors.dark, colors.doubleDark)
  const errorColor = useColorModeValue('red', 'red.300')
  const textColor = useColorModeValue('black', 'white')

  return {
    darkColor,
    textColor,
    errorColor
  }
}

export default useStyle
