import { ADMIN_DASH_PERMISSIONS, USER_TYPE } from '../../constants'
import { useEffect, useState } from 'react'
import { Box, Button, Menu, MenuButton, MenuItem, MenuList, Text } from '@chakra-ui/react'
import FileSaver from 'file-saver'
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import ClientSearch from 'components/ClientSearch'
import { containerWidth } from 'styles'
import useUIContext from 'hooks/ui-context'
import Card from 'components/Card'
import { setCurrentSearch } from 'store/modules/search'
import { exportCsv, getSummary } from 'services'
import LoadingBar from 'components/LoadingBar'
import { csvExportFilters } from 'utils'

const AdminDashboard = () => {
  const { setBreadcrumbs, addNotification } = useUIContext()
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const [summary, setSummary] = useState({})
  const [loading, setLoading] = useState(false)

  const loadSummary = async () => {
    setLoading(true)
    try {
      const res = await getSummary()
      setSummary(res.data)
    } catch (err) {
      addNotification({
        status: 'error',
        content: `failed to load Summary Information: ${err}`
      })
    }
    setLoading(false)
  }

  useEffect(() => {
    setBreadcrumbs([{
      path: '/admin/dashboard',
      label: 'Dashboard'
    }])
    loadSummary()
  }, [])

  const handleExportCsv = async (filter, filename) => {
    const res = await exportCsv(filter)
    console.log('res: ', res);
    const blob = new Blob([res.data], { type: 'text/plain;charset=utf-8' })
    FileSaver.saveAs(blob, filename)
  }

  return (
    <Box
      display='grid'
      gridTemplateColumns={{ base: '1fr', xl: '1fr 1fr'}}
      w={containerWidth}
      mx='auto'
      mb={12}
      gap={3}
      px={{base: 4, sm: 0}}
    >
      <ClientSearch
        search={{}}
        searchSubmit={search => {
          console.log('search: ', search);
          dispatch(setCurrentSearch({
            search,
            name: '',
            selected_talents: [],
            owner: null
          }))
          window.open('/admin/dashboard/search/new')
        }}
      />
      <Box minH={0}>
        <LoadingBar loading={loading} />
        <Box
          display='grid' gap={3} mt={7}
          gridTemplateColumns={{ base: '1fr', md: '1fr 1fr', lg: 'repeat(4, 1fr)' }}
        >
          <Card
            title='New'
            onClick={() => { navigate('/admin/dashboard/talents?status=new') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.new}</Text>
          </Card>
          <Card
            title='Accepted'
            onClick={() => { navigate('/admin/dashboard/talents?status=accepted') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.accepted}</Text>
          </Card>
          <Card
            title='Inactive'
            onClick={() => { navigate('/admin/dashboard/talents?status=inactive') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.inactive}</Text>
          </Card>
          <Card
            title='LTP'
            onClick={() => { navigate('/admin/dashboard/talents?status=long_term_pending') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.longTerm}</Text>
          </Card>
          <Card
            title='Declined'
            onClick={() => { navigate('/admin/dashboard/talents?status=declined') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.declined}</Text>
          </Card>
          <Card
            title='Pending decision'
            onClick={() => { navigate('/admin/dashboard/talents?status=pending_decision') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.pendingDecision}</Text>
          </Card>
          <Card
            title='Declined by Talent'
            onClick={() => { navigate('/admin/dashboard/talents?status=decline_from_talent') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.declineFromTalent}</Text>
          </Card>
          <Card
            title='Postpone by Talent'
            onClick={() => { navigate('/admin/dashboard/talents?status=talent_postponed_count') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.talentPostponed}</Text>
          </Card>
          <Card
            title='Recently deleted'
            onClick={() => { navigate('/admin/dashboard/talents?status=recently_deleted') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.recentlyDeleted}</Text>
          </Card>

          <Card title='Clients' onClick={() => { navigate('/admin/dashboard/clients') }}>
            <Text textAlign='center'>{summary.clients}</Text>
          </Card>
          <Card
            title='Searches'
            onClick={() => { navigate('/admin/dashboard/searches') }}
            bodyProps={{ cursor: 'pointer' }}
          >
            <Text textAlign='center'>{summary.searches}</Text>
          </Card>
          <Card
            title='Affiliations'
            onClick={() => { navigate('/admin/dashboard/talents-affiliation') }}
            bodyProps={{ cursor: 'pointer', px: 0 }}
          >
            <Text textAlign='center'>View/Edit</Text>
          </Card>
          <Menu>
            <MenuButton as={Button} >
              Export CSV
            </MenuButton>
            <MenuList>
              {Object.values(csvExportFilters).map(filterItem => {
                return <MenuItem onClick={() => {
                  handleExportCsv(filterItem.filter, filterItem.label + '.csv')
                }} key={filterItem.label}>{filterItem.label}</MenuItem>
              })}
            </MenuList>
          </Menu>
        </Box>
      </Box>
    </Box>
  )
}

export default AdminDashboard
