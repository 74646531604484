import { useState } from 'react'
import { useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'
import { useDispatch } from 'react-redux'
import { FormErrorMessage, FormLabel, FormControl, Input, Button, Box, Image } from '@chakra-ui/react'
import { useNavigate, useLocation } from 'react-router-dom'
import Error from 'components/Error'
import { login } from 'services'
import { setToken, setCurrentUser } from 'store/modules/user'
import PalomaLogo from 'asset/logo.jpg'
import useStyle from 'hooks/style.'
import { colors } from 'styles'
import jwt_decode from 'jwt-decode';

let logoutTimer = null;

const LoginForm = ()  => {
  const {
    handleSubmit,
    register,
    formState: { errors, isSubmitting },
  } = useForm()
  const [apiError, setApiError] = useState('')
  const dispatch = useDispatch()
  const navigate = useNavigate()
  const { darkColor } = useStyle()
  const useQuery = () => new URLSearchParams(useLocation().search);
  let query = useQuery();

  const startLogoutTimer = (token) => {
    const decoded = jwt_decode(token);
    const currentTime = Date.now() / 1000;
    const TOKEN_EXPIRATION_TIME = Math.floor(decoded.exp - currentTime) * 1000;

    // Clear the existing timer (if any)
    clearTimeout(logoutTimer);
    // Start a new timer for the token expiration time
    logoutTimer = setTimeout(() => {
      // Clear the user's authentication state
      dispatch(setToken(null))
      navigate('/auth/login')
    }, TOKEN_EXPIRATION_TIME);
  };

  const onSubmit = async (values) => {
    const res = await login(values.username, values.password)
    if (res.data && res.data.error) {
      setApiError(res.data.error)
    } else {
      dispatch(setToken(res.data.token))
      dispatch(setCurrentUser(res.data.user))
      startLogoutTimer(res.data.token)
      const userType = res.data.user.user_type
      setTimeout(() => {
        if (query.get('from')) {
          navigate(`${query.get('from')}`)
        } else {
          navigate(`/${userType}/dashboard`)
        }
      }, 500)
    }
  }

  return (
    <Box alignItems='center' justifyContent='center' height='100vh' display='flex' flexDirection='column'>
      <Image
        width={400}
        src={PalomaLogo}
        float='left'
        mb={5}
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <FormControl isInvalid={errors.name} w={350}>
          <FormLabel fontSize={22} mb={5} textAlign='center'>Login</FormLabel>
          <FormLabel htmlFor='username'>Username</FormLabel>
          <Input
            id='username'
            placeholder='username'
            {...register('username', {
              required: 'This is required',
              minLength: { value: 4, message: 'Minimum length should be 4' },
            })}
          />
          <FormLabel htmlFor='name' mt={5}>Password</FormLabel>
          <Input
            id='password'
            placeholder='Password'
            type='password'
            {...register('password', {
              required: 'This is required',
              minLength: { value: 4, message: 'Minimum length should be 4' },
            })}
          />
          <FormErrorMessage>
            {(errors.name && errors.name.message)}
          </FormErrorMessage>
          <Error error={apiError} />
        </FormControl>
        <Box justifyContent='space-between' display='flex' alignItems='center'>
          <Link to='/auth/reset-password-request'>
            Forgot password?
          </Link>
          <Button mt={5} bgColor={darkColor} textColor={colors.primaryText} isLoading={isSubmitting} type='submit'>
            Login
          </Button>
        </Box>
      </form>
    </Box>
  )
}

export default LoginForm
