const colors = {
  primary: 'gray.900',
  primaryText: 'white',
  white: 'white',
  gray: 'gray.100',
  light: 'green.200',
  dark: 'green.600',
  doubleDark: 'green.700'
}

const containerWidth = [
  '100%', // 0-30em
  '80%', // 30em-48em
  '75%', // 48em-62em
  '65%', // 62em+
]

export {
  colors,
  containerWidth
}
