import { createSlice } from '@reduxjs/toolkit'
import { listSearches, getOneSearch, createSearch, updateSearch, deleteSearch } from 'services'

const searchSlice = createSlice({
  name: 'search',
  initialState: {
    currentSearch: {
      search: { accepted_only: true },
      selected_talents: []
    },
    searches: { searches: [] }
  },
  reducers: {
    setCurrentSearch: (state, action) => {
      state.currentSearch = {
        ...state.currentSearch,
        ...action.payload
      }
    },
    setSearches: (state, action) => {
      state.searches = action.payload
    },
    clearCurrentSearch: (state) => {
      state.currentSearch = {
        search: {},
        selected_talents: []
      }
    }
  }
})

export const listSearchesAndSet = async (filter, dispatch) => {
  const searchesRes = await listSearches(filter)
  dispatch(setSearches(searchesRes.data))
  return searchesRes.data
}

export const getOneSearchAndSet = async (searchId, dispatch) => {
  const searchRes = await getOneSearch(searchId)
  dispatch(setCurrentSearch(searchRes.data))
  return searchRes.data
}

export const createSearchAndSet = async (search, dispatch) => {
  const searchRes = await createSearch(search)
  dispatch(setCurrentSearch(searchRes.data))
  return searchRes.data
}

export const updateSearchAndSet = async (id, search, dispatch) => {
  const searchRes = await updateSearch(id, search)
  dispatch(setCurrentSearch(searchRes.data))
  return searchRes.data
}

export const deleteSearchAndSet = async (id, dispatch) => {
  const deleteRes = await deleteSearch(id)
  return deleteRes.data
}

export const {
  setCurrentSearch,
  clearCurrentSearch,
  setSearches
} = searchSlice.actions

export default searchSlice.reducer
