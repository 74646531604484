import { useEffect } from 'react'
import { Box, Text, Select, HStack } from '@chakra-ui/react'
import { colors } from 'styles'

const Pagination = ({ pagination, setPagination, perPageCountIncludes }) => {
  const { page, perPage = 10, totalPages } = pagination || {}

  useEffect(() => {
    if (pagination && page >= totalPages) {
      setPagination({
        ...pagination,
        page: 0
      })
    }
  }, [page, totalPages])

  if (!pagination || !setPagination) { return null }

  return (
    <Box display='flex' alignItems='center' justifyContent='end' gridGap={3}>
      <Select variant='outline' size='sm' w={20} value={perPage} onChange={ev => {
        setPagination({ ...pagination, perPage: ev.target.value })
      }}>
        {perPageCountIncludes.map((count, idx) => (
          <option key={idx} value={parseInt(count)}>{count}</option>
        ))}
      </Select>
      <Text mr={5}>Per page</Text>
      {page >= 1 && <Text cursor='pointer' onClick={() => {
        setPagination({ ...pagination, page: page - 1 })
      }}>{'<'}</Text>}

      <HStack flexWrap='nowrap' alignItems='center'>
        <Select size='sm' variant='outline' value={page} onChange={ev => {
          setPagination({ ...pagination, page: parseInt(ev.target.value)})
        }}>
          {new Array(totalPages || 0).fill(0).map((_, idx) => {
            return <option value={idx} key={idx}>{idx + 1}</option>
          })}
        </Select>
        <Text>/</Text>
        <Text>
          {totalPages}
        </Text>
      </HStack>

      {page < totalPages - 1 && <Text cursor='pointer' onClick={() => {
        setPagination({ ...pagination, page: page + 1 })
      }}>{'>'}</Text>}
    </Box>
  )
}

export default Pagination
