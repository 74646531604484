import { Box, Text, Menu, MenuButton, MenuList, Image,
  MenuItem, Button } from '@chakra-ui/react'
import { useDispatch } from 'react-redux'
import { FaUser } from 'react-icons/fa'
import { setToken } from 'store/modules/user'
import { clearPersistedState } from 'store/index'
import useStyle from 'hooks/style.'
import { useNavigate } from 'react-router-dom'
import { API_URL } from 'constants'
import { USER_TYPES } from 'constants'
import { colors } from 'styles'
import { SUPER_ADMIN_USERNAMES } from 'constants'

const Header = ({ user }) => {
  const dispatch = useDispatch()
  const { darkColor } = useStyle()
  const navigate = useNavigate()

  return (
    <Box display='flex' height={14} bg={darkColor} alignItems='center' px={5} color={colors.primaryText}>
      <Text cursor='pointer' onClick={() => {
        window.location.href = '/'
      }} fontSize={{ base: '20px', md: 32}}>Paloma Model &amp; Talent </Text>

      <Box ml='auto'>
        {user && (
          <Menu>
            <MenuButton as={Button} bg={darkColor} rightIcon={user.logo ? 
              <Image
                height={30}
                borderRadius={5}
                overflow='hidden'
                src={`${API_URL}/s3/${encodeURIComponent(user.logo)}`}
              />  : <FaUser />
            } >
              Hello {user.first_name} {user.last_name}!
            </MenuButton>
            <MenuList color={colors.primary}>
              {user.user_type === USER_TYPES.ADMIN && (
                <MenuItem onClick={() => {
                  navigate('/enum-edit')
                }}>Edit Enum</MenuItem>
              )}
              {user.user_type === USER_TYPES.ADMIN && SUPER_ADMIN_USERNAMES.includes(user?.username) && (
                <MenuItem onClick={() => {
                  navigate('/admin-edit')
                }}>Edit Admins</MenuItem>
              )}
              {user.user_type !== USER_TYPES.TALENT && (
                <MenuItem onClick={() => {
                  navigate('/profile')
                }}>
                  <Box>
                    <Text>{user.username}</Text>
                    <Text>({user.email})</Text>
                  </Box>
                </MenuItem>
              )}
              <MenuItem onClick={() => {
                dispatch(setToken(null))
                clearPersistedState()
                navigate('/auth/login')
              }}>Logout</MenuItem>
            </MenuList>
          </Menu>
        )}
      </Box>
    </Box>
  )
}

export default Header
