import { createSlice } from '@reduxjs/toolkit'

const appSlice = createSlice({
  name: 'app',
  initialState: {
    rootLoading: false,
    confirmModal: {
      show: false,
      title: '',
      content: '',
      variant: 'confirm',
    }
  },
  reducers: {
    setRootLoading: (state, action) => {
      state.rootLoading = action.payload
    },
    setConfirmModal: (state, action) => {
      state.confirmModal = {
        ...state.confirmModal,
        ...action.payload
      }
    }
  }
})

export const {
  setRootLoading,
  setConfirmModal
} = appSlice.actions

export default appSlice.reducer
