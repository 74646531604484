import { useDispatch, useSelector } from 'react-redux'
import { getOneSearchAndSet, listSearchesAndSet, deleteSearchAndSet,
  createSearchAndSet, updateSearchAndSet } from 'store/modules/search'

const useSearch = () => {
  const dispatch = useDispatch()
  const currentSearch = useSelector(state => state.search.currentSearch)
  const searches = useSelector(state => state.search.searches)

  const getOneSearch = async (searchId) => {
    return await getOneSearchAndSet(searchId, dispatch)
  }

  const listSearches = async (filter) => {
    return await listSearchesAndSet(filter, dispatch)
  }

  const createSearch = async (field) => {
    return await createSearchAndSet(field, dispatch)
  }
  
  const updateSearch = async (id, field) => {
    return await updateSearchAndSet(id, field, dispatch)
  }

  const deleteSearch = async (id) => {
    return await deleteSearchAndSet(id, dispatch)
  }

  return {
    currentSearch,
    searches,
    getOneSearch,
    listSearches,
    createSearch,
    updateSearch,
    deleteSearch
  }
}

export default useSearch
