import { useState } from 'react'
import { Text, Box, FormControl, FormLabel, Button, useDisclosure, Modal, ModalOverlay,
  ModalContent, FormErrorMessage, Input, TableContainer, Table, Thead, Tr, Th, Tbody, Td } from '@chakra-ui/react'
import { containerWidth } from 'styles'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import Card from 'components/Card'
import { FaUserPlus, FaPen, FaTrash } from 'react-icons/fa'
import Select from 'components/Select'
import Error from 'components/Error'
import useTalent from 'hooks/talent'
import { USER_TYPES } from 'constants'

const TalentContacts = ({ asComponent }) => {
  const user = useSelector(state => state.user.currentUser)
  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    reset
  } = useForm()
  const {talent, updateTalent} = useTalent()
  const enumData = useSelector(state => state.talent.enumData)
  const { isOpen, onOpen: openContactModal, onClose } = useDisclosure()
  const [apiError, setApiError] = useState('')
  const [cIdx, setCIdx] = useState(-1)
  const [isSubmitting, setIsSubmitting] = useState(false)

  if (!talent) { return null }

  const saveContact = async (ev) => {
    ev.preventDefault()
    ev.stopPropagation()
    const values = getValues()
    setIsSubmitting(true)
    try {
      let updatedContacts = talent.contacts.slice(0)
      if (cIdx === -1) { updatedContacts.push(values) }
      else { updatedContacts[cIdx] = values }
      await updateTalent({ contacts: updatedContacts })
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
    onClose()
  }

  const removeContact = async (idx) => {
    let updatedContacts = talent.contacts.slice(0)
    updatedContacts.splice(idx, 1)
    setIsSubmitting(true)
    try {
      await updateTalent({ contacts: updatedContacts })
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
  }

  const TableWrapper = asComponent ? Box: Card

  return (
    <Box width={asComponent ? '100%': containerWidth} mx='auto' display='flex' flexDirection='column'>
      {user && user.user_type !== USER_TYPES.ADMIN && <Text textAlign='center' mb={3}>
        If you change your primary contact info, please inform us at <a href='mailto:accounting@palomamodelandtalent.com'>accounting@palomamodelandtalent.com</a> and also change your info on all casting sites as necessary. Thanks!
      </Text>}
      <Button ml='auto' mb={3} disabled={isSubmitting} leftIcon={<FaUserPlus />} onClick={() => {
        reset({})
        setCIdx(-1)
        openContactModal()
      }}>
        Add Contact
      </Button>
      <TableWrapper title='Contact Information' >
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>First Name</Th>
                <Th>Last Name</Th>
                <Th>Type</Th>
                <Th>Email</Th>
                <Th>Relationship</Th>
                <Th>Cell Phone #</Th>
                {/* <Th>Cell Carrier</Th> */}
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {talent.contacts.length === 0 && (
                <Tr>
                  <Td colSpan={7} textAlign='center'>
                    {asComponent ? 
                      <Box textAlign='center'>
                        <Text fontSize='xl'>No contacts.</Text>
                      </Box>
                    : (
                      <Box textAlign='center'>
                        <Text fontSize='xl' mb={3}>You do not have any added contacts.</Text>
                        <Text fontSize='lg' w='50vw' mx='auto' whiteSpace='normal'>
                          For minors, please add all legal guardians, as well as anyone else assisting with their endeavors. For adult talent, please add your own info here, along with others that we can contact in case we cannot reach you.
                        </Text>
                      </Box>
                    )}
                  </Td>
                </Tr>
              )}
              {talent.contacts.map((contact, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{contact.first_name}</Td>
                    <Td>{contact.last_name}</Td>
                    <Td>{contact.contact_type}</Td>
                    <Td>{contact.email}</Td>
                    <Td>{contact.relationship}</Td>
                    <Td>{contact.cell_number}</Td>
                    {/* <Td>{contact.cell_carrier}</Td> */}
                    <Td>
                      <Box display='grid' gridGap={2} gridAutoFlow='column'>
                        <FaPen onClick={() => {
                          reset(contact)
                          setCIdx(idx)
                          openContactModal()
                        }} />
                        <FaTrash onClick={() => {
                          removeContact(idx)
                        }} />
                      </Box>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </TableWrapper>

      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Card title='New Contact' bodyProps={{width: '100%',  px: 7}}>
              <form onSubmit={ev => handleSubmit(saveContact(ev))}>
                <Box>
                  <FormControl isInvalid={errors && errors.first_name}>
                    <FormLabel>First Name *</FormLabel>
                    <Input {...register('first_name', { required: 'This field is required'})} />
                    <FormErrorMessage>{(errors.first_name && errors.first_name.message)}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors && errors.last_name}>
                    <FormLabel>Last Name *</FormLabel>
                    <Input {...register('last_name', { required: 'This field is required'})} />
                    <FormErrorMessage>{(errors.last_name && errors.last_name.message)}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors && errors.contact_type}>
                    <FormLabel>Type *</FormLabel>
                    <Select control={control}
                      name='contact_type' rules={{ required: 'This field is required'}}
                      options={enumData.contact_type}
                    />
                    <FormErrorMessage>{(errors.contact_type && errors.contact_type.message)}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors && errors.relationship}>
                    <FormLabel>Relationship *</FormLabel>
                    <Input {...register('relationship', { required: 'This field is required'})} />
                    <FormErrorMessage>{(errors.relationship && errors.relationship.message)}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors && errors.email}>
                    <FormLabel>Email *</FormLabel>
                    <Input type='email' {...register('email', { required: 'This field is required'})} />
                    <FormErrorMessage>{(errors.email && errors.email.message)}</FormErrorMessage>
                  </FormControl>
                  <FormControl isInvalid={errors && errors.cell_number}>
                    <FormLabel>Phone # *</FormLabel>
                    <Input {...register('cell_number', { required: 'This field is required'})} />
                    <FormErrorMessage>{(errors.cell_number && errors.cell_number.message)}</FormErrorMessage>
                  </FormControl>
                  {/* <FormControl isInvalid={errors && errors.cell_carrier}>
                    <FormLabel>Cell Carrier *</FormLabel>
                    <Input {...register('cell_carrier', { required: 'This field is required'})} />
                    <FormErrorMessage>{(errors.cell_carrier && errors.cell_carrier.message)}</FormErrorMessage>
                  </FormControl> */}

                  <Error error={apiError} />
                  <Box mt={5} display='flex' justifyContent='center'>
                    <Button disabled={isSubmitting} isLoading={isSubmitting} mr={2} type='submit'>Save</Button>
                    <Button disabled={isSubmitting} onClick={onClose}>Cancel</Button>
                  </Box>
                </Box>
              </form>
          </Card>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default TalentContacts
