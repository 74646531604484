import { useEffect, useState } from 'react'
import { Text, Box, FormControl, FormLabel, Button,
  FormErrorMessage, Input, Textarea, FormHelperText } from '@chakra-ui/react'
import { containerWidth } from 'styles'
import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import { useForm } from 'react-hook-form'
import Card from 'components/Card'
import DateInput from 'components/DateInput'
import Select from 'components/Select'
import Error from 'components/Error'
import useTalent from 'hooks/talent'
import useUIContext from 'hooks/ui-context'
import { validateYear } from "utils";
import TalentSocialFormSection from 'components/TalentSocialFormSection'
import { getLoggedInUser, updateUsername } from 'services'
import { useDispatch } from 'react-redux'
import { setCurrentUser } from 'store/modules/user'

const TalentDetails = () => {
  const {talent, updateTalent} = useTalent()
  const { addNotification } = useUIContext()
  const user = useSelector(state => state.user.currentUser)

  const {
    control,
    handleSubmit,
    register,
    formState: { errors },
    setValue,
    getValues,
    setError,
    reset
  } = useForm({ defaultValues: talent })
  const enumData = useSelector(state => state.talent.enumData)
  const dispatch = useDispatch()
  const [apiError, setApiError] = useState('')
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const submitData = async () => {
    setIsSubmitting(true)
    setApiError('')
    try {
      const values = getValues()

      const res = await updateUsername({ username: values.username })
      if (!res.data.success) {
        setError('username', { type: 'focus', message: res.data.error }, { shouldFocus: true })
        throw res.data.error
      }
  
      await updateTalent(values.talent)
      const userRes = await getLoggedInUser()
      dispatch(setCurrentUser(userRes.data))
      addNotification({
        status: 'success',
        content: 'Saved'
      })
    } catch (err) {
      console.log('err: ', err);
      setApiError(err || 'Save Failed')
    }
    setIsSubmitting(false)
  }

  useEffect(() => {
    if (!talent || !user) { return }
    reset({
      talent: talent,
      username: user.username
    })
  }, [talent, user])

  if (!talent) { return null }

  return (
    <Box width={containerWidth} mx='auto' mb={8}>
      <Text textAlign='center' mb={8}>
        If you change your primary contact info, please inform us at <a href='mailto:accounting@palomamodelandtalent.com'>accounting@palomamodelandtalent.com</a> and also change your info on all casting sites. Thanks!
      </Text>
      <Card title='My Details'>
        <form onSubmit={handleSubmit(submitData)}>
          <Box display='grid' gridTemplateColumns={{base: '1fr', lg: '1fr 1fr'}} mt={3} gridGap={3}>
            <Text textAlign='center'>
              Stage Name: {talent.is_accepted ?`${talent.stage_first_name || ''} ${talent.stage_last_name || ''}`.trim('') || 'N/A' :'-'}
            </Text>
            <Text textAlign='center'>
              Union Status: {talent.union_status}
            </Text>
          </Box>
          <Box my={2}>
            <Text fontSize='sm' textAlign='justify'>
              If you are working in the television and film space and use an established stage name, please email admin@palomamodelandtalent.com to notify us and have the stage name added to your profile. If your union status is incorrect, please also reach out to Admin. Until you book a principal role on a SAG-AFTRA job, you are non-union.
            </Text>
          </Box>
          <Box display='grid' gridTemplateColumns={{base: '1fr', lg: '1fr 1fr', xl:'repeat(3, 1fr)'}} mt={3} gridGap={3}>
            <FormControl isInvalid={errors.talent && errors.talent.first_name}>
              <FormLabel>First Name *</FormLabel>
              <Input readOnly {...register('talent.first_name', { required: 'This field is required' })}/>
              <FormErrorMessage>{(errors.talent && errors.talent.first_name && errors.talent.first_name.message)}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.last_name}>
              <FormLabel>Last Name *</FormLabel>
              <Input readOnly {...register('talent.last_name', { required: 'This field is required' })}/>
              <FormErrorMessage>{(errors.talent && errors.talent.last_name && errors.talent.last_name.message)}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.email}>
              <FormLabel>Email *</FormLabel>
              <Input type='email' {...register('talent.email', { required: 'This field is required'})} />
              <FormErrorMessage>{(errors.talent && errors.talent.email && errors.talent.email.message)}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.phones && errors.talent.phones[0]}>
              <FormLabel>Phone (1) *</FormLabel>
              <Input {...register('talent.phones[0]', { required: 'This field is required'})} />
              <FormErrorMessage>{(errors.talent && errors.talent.phones && errors.talent.phones[0] && errors.talent.phones[0].message)}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Phone (2)</FormLabel>
              <Input {...register('talent.phones[1]')} />
            </FormControl>
            <FormControl isInvalid={errors.username}>
              <FormLabel>Username *</FormLabel>
              <Input {...register('username', { required: 'This field is required'})} />
              <FormErrorMessage>{(errors.username && errors.username.message)}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.talent && errors.talent.gender}>
              <FormLabel>Gender *</FormLabel>
              <Select control={control}
                isMulti
                name='talent.gender' rules={{ required: 'This field is required'}}
                options={enumData.gender}
              />
              <FormErrorMessage>{(errors.talent && errors.talent.gender && errors.talent.gender.message)}</FormErrorMessage>
            </FormControl>
            {typeof talent.is_over_25 !== 'boolean' ? (
              <FormControl isInvalid={errors.talent && errors.talent.birth_date}>
                <FormLabel>Date of Birth *</FormLabel>
                <DateInput control={control} name='talent.birth_date' rules={{ required: 'This field is required', validate: validateYear }} />
                <FormErrorMessage>{(errors.talent && errors.talent.birth_date && errors.talent.birth_date.message)}</FormErrorMessage>
              </FormControl>
            ): (
              <>
                <FormControl isInvalid={errors.talent && errors.talent.birth_date}>
                  <FormLabel>Date of Birth</FormLabel>
                  <DateInput control={control} name='talent.birth_date' rules={{ required: false, validate: validateYear }} />
                  <FormErrorMessage>{(errors.talent && errors.talent.birth_date && errors.talent.birth_date.message)}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors.talent && errors.talent.is_over_25}>
                  <FormLabel>Is Over 25 ?</FormLabel>
                  <Select control={control}
                    name='talent.is_over_25'
                    options={enumData.boolean_value} 
                    // rules={{ required: 'You must select either over or under 25. We ask this to know if you are eligible to participate in alcohol advertising'}}
                  />
                  <FormErrorMessage>
                  {errors.talent && errors.talent.is_over_25 &&
                    "You must select either over or under 25. We ask this to know if you are eligible to participate in alcohol advertising"}
                </FormErrorMessage>
                </FormControl>
              </>
            )}
            <FormControl isInvalid={errors.talent && errors.talent.is_twin}>
              <FormLabel>Identical Twin</FormLabel>
              <Select control={control}
                name='talent.is_twin'
                options={enumData.boolean_value}
              />
              <FormErrorMessage>{(errors.talent && errors.talent.is_twin && errors.talent.is_twin.message)}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.talent && errors.talent.ethnicity_actual}>
              <FormLabel>Ethnic Heritage (actual) *</FormLabel>
              <Select control={control}
                isMulti
                name='talent.ethnicity_actual' rules={{ required: 'This field is required'}}
                options={enumData.ethnicity} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.ethnicity_actual && errors.talent.ethnicity_actual.message)}</FormErrorMessage>
            </FormControl>
            <FormControl gridColumn='1 / -1'>
              <FormLabel>Actual Ethnicity Specifics</FormLabel>
              <Textarea minHeight='unset' rows={3} {...register('talent.ethnicity_note')} />
              <FormHelperText>
                Please provide specific actual ethnicity details as many jobs seek specific heritage during the casting process. Please be as detailed as possible. Letting us know the specific heritage of each your parents and what % of any particular ethnicity you are will streamline the casting process when they require such information.
              </FormHelperText>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.ethnicity_look}>
              <FormLabel>Racial Appearance *</FormLabel>
              <Select control={control}
                isMulti
                name='talent.ethnicity_look' rules={{ required: 'This field is required'}}
                options={enumData.racial_appearance} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.ethnicity_look && errors.talent.ethnicity_look.message)}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.talent && errors.talent.hair_color}>
              <FormLabel>Hair Color *</FormLabel>
              <Select control={control}
                name='talent.hair_color' rules={{ required: 'This field is required'}}
                options={enumData.hair_color} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.hair_color && errors.talent.hair_color.message)}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.cloth_size}>
              <FormLabel>Clothing Size *</FormLabel>
              <Select control={control}
                name='talent.cloth_size' rules={{ required: 'This field is required'}}
                options={enumData.cloth_size} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.cloth_size && errors.talent.cloth_size.message)}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.eye_color}>
              <FormLabel>Eye Color *</FormLabel>
              <Select control={control}
                name='talent.eye_color' rules={{ required: 'This field is required'}}
                options={enumData.eye_color} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.eye_color && errors.talent.eye_color.message)}</FormErrorMessage>
            </FormControl>

            <FormControl isInvalid={errors.talent && errors.talent.shoe_size}>
              <FormLabel>Shoe Size *</FormLabel>
              <Select control={control}
                name='talent.shoe_size' rules={{ required: 'This field is required'}}
                options={enumData.shoe_size} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.shoe_size && errors.talent.shoe_size.message)}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.height}>
              <FormLabel>Height *</FormLabel>
              <Select control={control}
                name='talent.height' rules={{ required: 'This field is required'}}
                options={enumData.height} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.height && errors.talent.height.message)}</FormErrorMessage>
            </FormControl>
            <FormControl isInvalid={errors.talent && errors.talent.weight}>
              <FormLabel>Weight *</FormLabel>
              <Select control={control}
                name='talent.weight' rules={{ required: 'This field is required'}}
                options={enumData.weight} 
              />
              <FormErrorMessage>{(errors.talent && errors.talent.weight && errors.talent.weight.message)}</FormErrorMessage>
            </FormControl>
            <FormControl>
              <FormLabel>Special Skills</FormLabel>
              <Select control={control}
                isMulti
                name='talent.special_skills'
                options={enumData.skills} 
              />
            </FormControl>
            <FormControl>
              <FormLabel>Other abled?</FormLabel>
              <Select control={control}
                name='talent.other_abled'
                options={enumData.boolean_value}
              />
            </FormControl>
          </Box>
          
          <Box display='grid' gridTemplateColumns={{base: '1fr', lg: '1fr 1fr', xl:'repeat(3, 1fr)'}} mt={3} gridGap={3}>
            <FormControl>
              <FormLabel>Casting Frontier Barcode</FormLabel>
              <Input {...register('talent.casting_frontier_barcode')} />
            </FormControl>
            <FormControl>
              <FormLabel>Work Permit Expiration Date</FormLabel>
              <DateInput control={control} name='talent.work_permit_exp' />
            </FormControl>
            {(talent.union_status === 'SAG-AFTRA' || talent.union_status === 'SAG-AFTRA-E') && (
              <FormControl>
                <FormLabel>SAG-AFTRA ID </FormLabel>
                <Input {...register('talent.sag_aftra_id')} />
              </FormControl>
            )}
          </Box>
          <FormControl gridColumn='1 / -1' isInvalid={errors.talent && errors.talent.is_homeschooled} my={4}>
            <FormLabel>Is Homeschooled</FormLabel>
            <Select control={control}
              name='talent.is_homeschooled'
              options={enumData.boolean_value} 
            />
          </FormControl>
          <Box mx={8}>
            <Text textAlign='center' m={8}>
              Please enter your handle for any PUBLIC accounts and the appropriate URL will populate for all fields except your personal website and IMDb. Please verify all links before finalizing your submission. Do not add any links to private accounts.
            </Text>
            <TalentSocialFormSection prefix='talent' register={register} setValue={setValue} />
            <FormControl gridColumnStart='1'>
              <FormLabel>Also represented by</FormLabel>
              <Input {...register('talent.represented_by')} />
            </FormControl>
            <FormControl>
              <FormLabel>Message</FormLabel>
              <Textarea {...register('talent.message')} />
            </FormControl>
          </Box>
          <Error error={apiError} />
          <Box display='flex' justifyContent='center' mt={7}>
            <Button disabled={isSubmitting} isLoading={isSubmitting}  mr={2} type='submit'>Save</Button>
            <Button disabled={isSubmitting} onClick={() => {
              navigate('/talent/dashboard')
            }}>Back</Button>
          </Box>
        </form>
      </Card>
    </Box>
  )
}

export default TalentDetails
