import React, { useState, useEffect } from 'react'
import useTalent from 'hooks/talent'
import { useParams } from 'react-router-dom'
import { Box, IconButton, Image, Text, Link, useMediaQuery, Modal, ModalContent, ModalOverlay, Button, HStack, LightMode, useColorModeValue, ModalCloseButton, ModalBody, Tooltip } from '@chakra-ui/react'
import { likesDisplay, getDatePart, getOptionLabel } from 'utils'
import { useSelector } from 'react-redux'
import { USER_TYPES } from 'constants'
import moment from 'moment'
import useStyle from 'hooks/style.'
import { API_URL } from 'constants'
import PalomaLogo from 'asset/logo.jpg'
import { FaCopy, FaFacebook, FaFilePdf, FaGlobe, FaInstagram, FaTiktok, FaImdb, FaYoutube, FaPrint } from 'react-icons/fa'
import TalentBookInfo from 'components/TalentBookInfo'
import { colors } from 'styles'

const Icons = {
  facebook: <FaFacebook size={20} />,
  imdb: <FaImdb size={20} />,
  instagram: <FaInstagram size={20} />,
  personal: <FaGlobe size={20} />,
  tiktok: <FaTiktok size={20} />,
  youtube: <FaYoutube size={20} />
}

const HelperText = {
  facebook: 'Facebook',
  imdb: 'IMDB',
  instagram: 'Instagram',
  personal: 'Personal Website',
  tiktok: 'Tiktok',
  youtube: 'Youtube'
}

const socialKeys = ['facebook', 'tiktok', 'instagram', 'youtube', 'imdb', 'personal']

const TalentView = () => {
  const { slug } = useParams()
  const { talent, getTalentBySlugAndSet } = useTalent()
  const user = useSelector(state => state.user.currentUser)
  const enumData = useSelector(state => state.talent.enumData)
  const [showBookInfo, setShowBookInfo] = useState(false)
  const { darkColor } = useStyle()

  const [selectedPhotoIndex, setSelectedPhotoIndex] = useState(null);
  const [isPhotoModalOpen, setIsPhotoModalOpen] = useState(false);

  const handlePhotoClick = (index) => {
    setSelectedPhotoIndex(index);
    setIsPhotoModalOpen(true);
  };

  const handlePhotoCloseModal = () => {
    setSelectedPhotoIndex(null);
    setIsPhotoModalOpen(false);
  };


  useEffect(() => {
    getTalentBySlugAndSet(slug)
  }, [slug])

  useEffect(() => {
  if (talent) {
    const name = talent.stage_first_name && talent.stage_last_name
      ? `${talent.stage_first_name} ${talent.stage_last_name}`
      : `${talent.first_name} ${talent.last_name}`;
    
    document.title = name;
  }
}, [talent]);

  if (!talent) { return <Text>Loading...</Text> }

  const name = talent?.stage_first_name && talent?.stage_last_name
  ? `${talent.stage_first_name} ${talent.stage_last_name}`
  : `${talent.first_name} ${talent.last_name}`;


  const talentAge = moment.duration(new Date() - new Date(talent?.birth_date))
  const ageText = talentAge?.asMonths() < 48
  ? `${Math.floor(talentAge?.asMonths())} months`
  : talentAge?.asYears() < 18
    ? `${Math.floor(talentAge.asYears())} years`
    : ''

  return (
    <Box>
      <Box className='hide-on-print' display='flex' flexDirection='column' h='100vh'>
        <Box px={5} display='flex' mt={5} alignItems='center' flexWrap='wrap-reverse'>
          <Text fontSize='4xl'>{name}</Text>
          <Tooltip label="Print" fontSize="md">
            <IconButton variant='ghost' ml={5} onClick={() => {
              window.print()
            }}>
              <FaPrint size={20} />
            </IconButton>
          </Tooltip>
          {talent?.resume ? (
            <Tooltip label="Resumé" fontSize="md">
              <IconButton variant='ghost' onClick={() => {
                window.open(`${API_URL}/s3/${encodeURIComponent(talent?.resume)}`)
              }}>
                <FaFilePdf size={20} />
              </IconButton>
            </Tooltip>
            
          ) : null }
          {socialKeys?.map(key => {
            if (!talent.social_links || !talent.social_links[key]) { return null }
            return (
              <HStack key={key} position='relative' alignItems='end'>
                <Tooltip label={HelperText[key]} fontSize="md">
                  <IconButton variant='ghost' as={Link} href={talent.social_links[key]} target='_blank'>
                    {Icons[key]}
                  </IconButton>
                </Tooltip>
                <Text textAlign='center' position='absolute' left={-2} bottom={-2} fontSize={12}>{likesDisplay(talent.social_links[`${key}_followers`])}</Text>
              </HStack>
            )
          })}

          {(user && user.user_type === USER_TYPES.ADMIN) &&

          <Button ml={5} leftIcon={<FaCopy />} onClick={() => {
            setShowBookInfo(true)
          }}>
            Generate Booking Information
          </Button>

          }
          <Image
            ml='auto'
            height={50}
            src={PalomaLogo}
          />
         
        </Box>
        <Box display='flex' rowGap={5} columnGap={40} bg={darkColor} color={colors.primaryText} py={5} my={5} px={5} flexWrap='wrap'>
          <Box display='flex' gap={10} flexWrap='wrap'>
            <Box><Text>Gender </Text><Text fontWeight='bold'>{getOptionLabel(talent.gender, enumData.gender, true)}</Text></Box>
            <Box><Text>Hair Color </Text><Text fontWeight='bold'>{getOptionLabel(talent.hair_color, enumData.hair_color)}</Text></Box>
            <Box><Text>Eye Color </Text><Text fontWeight='bold'>{getOptionLabel(talent.eye_color, enumData.eye_color)}</Text></Box>
          </Box>
          <Box display='flex' gap={10} flexWrap='wrap'>
            <Box><Text>Clothing Size </Text><Text fontWeight='bold'>{getOptionLabel(talent.cloth_size, enumData.cloth_size)}</Text></Box>
            <Box><Text>Shoe Size </Text><Text fontWeight='bold'>{getOptionLabel(talent.shoe_size, enumData.shoe_size)}</Text></Box>
            <Box><Text>Height </Text><Text fontWeight='bold'>{getOptionLabel(talent.height, enumData.height)}</Text></Box>
            <Box><Text>Weight </Text><Text fontWeight='bold'>{getOptionLabel(talent.weight, enumData.weight)}</Text></Box>
          </Box>
          <Box display='flex' gap={10} flexWrap='wrap'>
            {/* {typeof talent.is_over_25 !== 'boolean' && ( */}
            {talentAge.asYears() < 18 && (
              <Box>
                <Text>DOB </Text>
                <Text fontWeight='bold' mr={4}>
                  {getDatePart(talent.birth_date)}
                </Text>
              </Box>
            )}
            <Box>
              {ageText && <Text>Age </Text>}
              <Text fontWeight='bold' >
                {ageText}
              </Text>
            </Box>
          </Box>
        </Box>

        <Box alignItems='flex-start' display='flex' gap={5} flexWrap='nowrap' overflowX='auto' flex={1} pb={5} minH='50vh'>
          {talent?.photos?.map((photo, index) => {
            return (
              <Image
                key={photo}
                src={`${API_URL}/s3/${encodeURIComponent(photo)}`}
                h='full'
                onClick={() => handlePhotoClick(index)}
                cursor="pointer"
              />
            )
          })}
        </Box>
      </Box>

      <Box className='print-only'>
        <LightMode>
          <Box px={5} display='flex' mt={5} alignItems='center' flexWrap='wrap-reverse'>
            <Text color='black' fontSize='4xl'>{name}</Text>
            <IconButton variant='ghost' ml={5}>
              <FaFilePdf size={20} />
            </IconButton>
            {socialKeys?.map(key => {
              if (!talent.social_links || !talent.social_links[key]) { return null }
              return (
                <HStack key={key} position='relative' alignItems='end'>
                  <IconButton variant='ghost' as={Link} href={talent.social_links[key]} target='_blank'>
                    {Icons[key]}
                  </IconButton>
                  <Text textAlign='center' position='absolute' left={-2} bottom={-2} fontSize={12}>{likesDisplay(talent.social_links[`${key}_followers`])}</Text>
                </HStack>
              )
            })}
            <Image
              ml='auto'
              height={50}
              src={PalomaLogo}
            />
          </Box>
          <Box mx={5} display='flex' rowGap={5} columnGap={10} flexWrap='wrap'>
            <Box>
              <Text color='black'>Gender </Text>
              <Text color='black' fontWeight='bold'>{getOptionLabel(talent.gender, enumData.gender, true)}</Text>
            </Box>
            <Box>
              <Text color='black'>Hair Color </Text>
              <Text color='black' fontWeight='bold'>{getOptionLabel(talent.hair_color, enumData.hair_color)}</Text>
            </Box>
            <Box>
              <Text color='black'>Eye Color </Text>
              <Text color='black' fontWeight='bold'>{getOptionLabel(talent.eye_color, enumData.eye_color)}</Text>
            </Box>
            <Box>
              <Text color='black'>Clothing Size </Text>
              <Text color='black' fontWeight='bold'>{getOptionLabel(talent.cloth_size, enumData.cloth_size)}</Text>
            </Box>
            <Box>
              <Text color='black'>Shoe Size </Text>
              <Text color='black' fontWeight='bold'>{getOptionLabel(talent.shoe_size, enumData.shoe_size)}</Text>
            </Box>
            <Box>
              <Text color='black'>Height </Text>
              <Text color='black' fontWeight='bold'>{getOptionLabel(talent.height, enumData.height)}</Text>
            </Box>
            <Box>
              <Text color='black'>Weight </Text>
              <Text color='black' fontWeight='bold'>{getOptionLabel(talent.weight, enumData.weight)}</Text>
            </Box>
            <Box display='flex' gap={10} flexWrap='wrap'>
              {/* {typeof talent.is_over_25 !== 'boolean' && ( */}
              {talentAge.asYears() < 18 && (
                <Box>
                  <Text color='black'>DOB </Text>
                  <Text color='black' fontWeight='bold' mr={4}>
                    {getDatePart(talent.birth_date)}
                  </Text>
                </Box>
              )}
              <Box>
                {ageText && <Text color='black'>Age </Text>}
                <Text color='black' fontWeight='bold'>
                  {ageText}
                </Text>
              </Box>
            </Box>
          </Box>
          <Box mx={3} my={3} gap={3} display='grid' gridTemplateColumns='repeat(3, 1fr)'>
            {talent?.photos?.slice(0, 9)?.map(photo => {
              return (
                <Image
                  key={photo}
                  w='100%'
                  h='100%'
                  maxH='calc((100vh - 250px) / 3)'
                  src={`${API_URL}/s3/${encodeURIComponent('300x300/' + photo)}`}
                  objectFit='contain'
                  style={{ pageBreakBefore: 'avoid' }}
                />
              )
            })}
          </Box>
        </LightMode>
      </Box>

      <Modal isOpen={showBookInfo} onClose={() => {
        setShowBookInfo(false)
      }} blockScrollOnMount={false}>
        <ModalOverlay />
        <ModalContent>
          <TalentBookInfo
            talent={talent}
            close={() => {
              setShowBookInfo(false)
            }}
          />
        </ModalContent>
      </Modal>
      <Modal isOpen={isPhotoModalOpen} onClose={handlePhotoCloseModal} size="">
        <ModalOverlay />
        <ModalContent maxWidth="40vw">
          <ModalCloseButton />
          <ModalBody>
            <Image
              src={`${API_URL}/s3/${encodeURIComponent(talent.photos[selectedPhotoIndex])}`}
              maxHeight="90vh"
              mx="auto"
            />
          </ModalBody>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default TalentView
