import { useRef, useState } from 'react'
import { Text, FormControl, FormLabel, FormErrorMessage, Button, Box, Image, Badge } from '@chakra-ui/react'
import { useForm } from 'react-hook-form'
import { containerWidth } from 'styles'
import useTalent from 'hooks/talent'
import { API_URL } from 'constants'
import useUIContext from 'hooks/ui-context'

const ResumeUpload = ({ by_admin = false }) => {
  const {
    talent,
    uploadTalentResume: uploadResume,
    deleteTalentResume: deleteResume
  } = useTalent()
  const {
    handleSubmit,
    register,
    formState: { errors },
    reset
  } = useForm()
  const formRef = useRef(null)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [apiError, setApiError] = useState('')
  const {showConfirmModal} = useUIContext()

  if (!talent) return null

  const handleUpload = async () => {
    console.log('here');
    setIsSubmitting(true)
    try {
      const formData = new FormData(formRef.current)
      console.log('formData: ', formData);
      formData.append('by_admin', by_admin)
      await uploadResume(formData)
      reset()
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
  }

  const deleteConfirmHandle = async (action) => {
    if (action !== 'yes') { return }
    await deleteResume(by_admin)
  }

  return (
    <Box width={containerWidth} mx='auto' mb={9}>
      <form ref={formRef} onSubmit={handleSubmit(() => {})}>
        <FormControl isInvalid={errors && errors.files}>
          <FormLabel>Upload {talent.resume ? '' : 'New'} Resume</FormLabel>
            <Box display='flex' alignItems='end'>
              <input
                type='file'
                name='file'
                id="new-resume-upload"
                accept='application/pdf,application/vnd.ms-excel'
                {...register("file", { required: 'Please choose at least one file'})}
                style={{ display: 'none' }}
                onChange={() => {
                  console.log('there');
                  handleUpload()
                }}
              />
              <Button isLoading={isSubmitting} ml={3} type='submit' onClick={() => {
                document.querySelector('#new-resume-upload').click()
              }}>
                Upload {talent.resume ? 'New': ''} Resume (must be pdf file)
              </Button>
              {talent.resume && (
                <Button
                  colorScheme='red'
                  disabled={isSubmitting}  ml={3}
                  onClick={() => {
                    showConfirmModal({
                      title: 'Are you sure?',
                      content: 'You won\'t be able to revert this action!',
                      buttons: [{
                        label: 'Yes',
                        action: 'yes',
                        color: 'red'
                      }, {
                        label: 'Cancel',
                        action: 'cancel'
                      }],
                      callback: deleteConfirmHandle
                    })
                  }}
                >
                  Delete
                </Button>
              )}
            </Box>
          <FormErrorMessage>{(errors.files && errors.files.message)}</FormErrorMessage>
          <Text color='red'>{`${apiError}`}</Text>
        </FormControl>
      </form>
      <Box mt={7} height='90vh'>
        {talent.resume ?
          <iFrame
            title='Resume' src={`${API_URL}/s3/${encodeURIComponent(talent.resume)}`}
            width='100%'
            height='100%'
          />
          : <Text textAlign='center'> You don't have resume uploaded</Text>
        }
      </Box>
    </Box>
  )
}

export default ResumeUpload
