import { useEffect, useState } from "react";
import {
  Box,
  Button,
  IconButton,
  Input,
  Table,
  TableContainer,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useSelector } from "react-redux";
import LoadingBar from "components/LoadingBar";
import { containerWidth } from "styles";
import useUIContext from "hooks/ui-context";
import { useNavigate, useLocation } from "react-router-dom";
import { FaPen, FaPlus, FaTimes, FaTrash, FaCopy, FaChevronDown, FaChevronUp } from "react-icons/fa";
import ClientSelect from "components/ClientSelect";
import useSearch from "hooks/search";
import Pagination from "components/Pagination";
import Card from "components/Card";
import { USER_TYPES } from "constants";
import { formatDate } from "utils";

const ORDER_BY_LIST = {
  dateran: 'date_ran',
}

const ORDER_TYPE = {
  desc: 'desc',
  asc: 'asc'
}

const Searches = () => {
  const { listSearches, searches, deleteSearch } = useSearch();
  const user = useSelector((state) => state.user.currentUser);
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    totalPages: 1,
  });
  const { control, handleSubmit, register, getValues, reset } = useForm();
  const [loading, setLoading] = useState(false);
  const { setBreadcrumbs, showConfirmModal, addNotification } = useUIContext();
  const location = useLocation();
  const homePath = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const [orderBy, setOrderBy] = useState(ORDER_BY_LIST.dateran);
  const [orderType, setOrderType] = useState(ORDER_TYPE.desc);

  const loadSearches = async (filter) => {
    setLoading(true);
    try {
      await listSearches({
        ...filter,
        page_num: pagination.page,
        per_page: pagination.perPage,
        order_by: !!orderBy ? orderBy : 'date_ran',
        order_type: !!orderType ? orderType : 'desc'
      });
    } catch (err) {
      addNotification({
        status: "error",
        content: `Failed to load Searches. ${err}`,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    loadSearches();
  }, [pagination.page, pagination.perPage]);

  useEffect(() => {
    setPagination({
      ...pagination,
      totalPages: Math.ceil(searches.total_count / searches.per_page),
    });
  }, [searches]);

  useEffect(() => {
    loadSearches({});
    setBreadcrumbs([
      {
        path: `/${homePath}/dashboard`,
        label: "Dashboard",
      },
      {
        path: `/${homePath}/dashboard/searches`,
        label: "Searches",
      },
    ]);
  }, []);

  const deleteConfirmHandle = async (action, searchId) => {
    if (action !== "yes") {
      return;
    }
    await deleteSearch(searchId);
    await loadSearches(getValues());
  };

  const copyLink = (searchId) => {
    navigator.clipboard.writeText(
      `${window?.location?.origin}/client/dashboard/search/${searchId}`,
    );
    addNotification({
      status: 'success',
      content: 'Link is copied!'
    });
  };

  const handleSortby = (name) => () => {
    setOrderBy(name);
    if(orderBy === name) {
      switch (orderType) {
        case ORDER_TYPE.desc:
          setOrderType(ORDER_TYPE.asc)
          break;
        case ORDER_TYPE.asc:
          setOrderType(ORDER_TYPE.desc)
          break;
        default:
          setOrderType(ORDER_TYPE.desc)
          break;
      }
    } else {
      setOrderType(ORDER_TYPE.desc)
    }
  }

  useEffect(() => {
    if(!!orderBy && !!orderType) {
      loadSearches()
    }
  }, [orderBy, orderType])

  const renderSortIcon = (name) => {
    if(orderBy === name) {
      if(orderType === ORDER_TYPE.desc) {
        return <FaChevronUp />
      }
      if(orderType === ORDER_TYPE.asc) {
        return <FaChevronDown />
      }
    }
    return null
  }

  return (
    <Box width={containerWidth} mx="auto" pb={10}>
      <Box mb={5}>
        <Text>Filters</Text>
        <Box mb={3} mt={1} display="flex" gap={3}>
          <Button
            leftIcon={<FaPlus />}
            onClick={() => {
              navigate(`/${homePath}/dashboard/search/new`);
            }}
          >
            New
          </Button>
          <form style={{ flex: 1 }} onSubmit={handleSubmit(loadSearches)}>
            <Box display="flex" gap={3}>
              <Input placeholder="Search Name" flex={1} {...register("name")} />
              {user.user_type === USER_TYPES.ADMIN && (
                <Box flex={1}>
                  <ClientSelect control={control} name="owner" />
                </Box>
              )}
              <IconButton
                icon={<FaTimes />}
                onClick={() => {
                  reset();
                  loadSearches();
                }}
              />
              <Button type="submit">Search</Button>
            </Box>
          </form>
        </Box>
      </Box>
      <Card title="Searches">
        <TableContainer mb={3}>
          <LoadingBar loading={loading} />
          <Table>
            <Thead>
              <Tr>
                <Th _hover={{cursor: 'pointer'}} onClick={handleSortby(ORDER_BY_LIST.dateran)}>
                  <Box display='flex'>
                    Date Ran
                    <Text ml={2}>
                      {renderSortIcon(ORDER_BY_LIST.dateran)}
                    </Text>
                  </Box>
                </Th>
                {user.user_type === USER_TYPES.ADMIN && <Th>Client</Th>}
                {user.user_type === USER_TYPES.ADMIN && <Th>Client Name</Th>}
                <Th>Search Name</Th>
                <Th>Action</Th>
              </Tr>
            </Thead>
            <Tbody>
              {searches.searches.map((search) => {
                return (
                  <Tr key={search._id}>
                    <Td>
                      {search.date_ran ? formatDate(search.date_ran) : "-"}
                    </Td>
                    {user.user_type === USER_TYPES.ADMIN && (
                      <Td>{search.owner && search.owner.company}</Td>
                    )}
                    {user.user_type === USER_TYPES.ADMIN && (
                      <Td>{search.owner && search.owner.contact}</Td>
                    )}
                    <Td>{search.name}</Td>
                    <Td>
                    <Button
                        variant="text"
                        size="xs"
                        onClick={() => copyLink(search._id)}
                      >
                        <FaCopy />
                      </Button>
                      <Button
                        variant="text"
                        size="xs"
                        onClick={() => {
                          navigate(
                            `/${homePath}/dashboard/search/${search._id}`
                          );
                        }}
                      >
                        <FaPen />
                      </Button>
                      <Button
                        variant="text"
                        size="xs"
                        onClick={() => {
                          showConfirmModal({
                            title: "Are you sure?",
                            content: "You won't be able to revert this action!",
                            buttons: [
                              {
                                label: "Yes",
                                action: "yes",
                                color: "red",
                              },
                              {
                                label: "Cancel",
                                action: "cancel",
                              },
                            ],
                            payload: search._id,
                            callback: deleteConfirmHandle,
                          });
                        }}
                      >
                        <FaTrash />
                      </Button>
                    </Td>
                  </Tr>
                );
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination pagination={pagination} setPagination={setPagination} perPageCountIncludes={['1', '2', '10', '25', '50', '100']} />
      </Card>
    </Box>
  );
};

export default Searches;
