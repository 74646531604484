import React from 'react'
import moment from 'moment'
import { Box, Button, Flex, GridItem, Input, FormLabel } from '@chakra-ui/react'

const DateComponent = ({ setContract_exp_date, contract_exp_date, setWork_permit_exp, work_permit_exp, showInput, setShowInput }) => {

  const onPressCancel = () => {
    setContract_exp_date(null)
    setWork_permit_exp(null)
    setShowInput(false)
  }


  return (
  <GridItem gridColumn="1 / -1" className="date-component" gap={4} my={2}>
    {showInput ? 
    <Flex gap="2">
      <Button type='submit' colorScheme='teal' size='xs'>Save</Button> 
      <Button onClick={onPressCancel} type='button' colorScheme='teal' size='xs'>Cancel</Button> 
    </Flex>
      : 
      <Button onClick={() => setShowInput(true)} type='button' colorScheme='teal' size='xs'>Edit Dates</Button>}
    <Box display="flex" mt={2} gap={6} border='1px' borderColor='gray.200' borderRadius="md" p={2}>
      <div>
        <FormLabel>Contract Expiration Date</FormLabel>
        { showInput ? <Input
          type='date'
          onChange={ev => {
            setContract_exp_date(moment(ev.target.value).toDate())
          }}
          value={moment(contract_exp_date).format('YYYY-MM-DD')}
          opacity={contract_exp_date ? 1 : 0.5}
          defaultValue=""
        /> : <span>{contract_exp_date ? moment(contract_exp_date).format('YYYY-MM-DD') : '-' }</span> }
        
      </div>
      <div>
        <FormLabel>Work Permit Expiration Date</FormLabel>
        { showInput ? <Input
          type='date'
          onChange={ev => {
            setWork_permit_exp(moment(ev.target.value).toDate())
          }}
          value={moment(work_permit_exp).format('YYYY-MM-DD')}
          opacity={work_permit_exp ? 1 : 0.5}
          defaultValue=""
        /> : <span>{work_permit_exp ? moment(work_permit_exp).format('YYYY-MM-DD') : '-' }</span> }
      </div>
    </Box>
      
  </GridItem>
  );
};

export default DateComponent
