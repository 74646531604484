import React, { useState, useEffect } from 'react'
import { resetPassword } from 'services'
import useUIContext from 'hooks/ui-context';
import { Input, Button, Text, Box, Image } from '@chakra-ui/react'
import PalomaLogo from 'asset/logo.jpg'
import jwt_decode from "jwt-decode";
import { useNavigate, useSearchParams } from 'react-router-dom';

const ResetPasswordRequest = (props) => {
  const [username, setUsername] = useState('')
  const [password, setPassword] = useState('')
  const [passwordConfirm, setPasswordConfirm] = useState('')
  const [error, setError] = useState('')
  const [formError, setFormError] = useState('')
  const [info, setInfo] = useState({})
  const [searchParams] = useSearchParams()
  const navigate = useNavigate()
  const token = searchParams.get('token')
  const choice = searchParams.get('choice')
  const resetUsername = searchParams.get('resetUsername') === 'true'
  const {addNotification } = useUIContext()

  useEffect(() => {
    document.title = `Check In | Reset password`;
    const data = jwt_decode(token)
    setInfo(data)
    setUsername(data.username)
  }, [])

  useEffect(() => {
    if (passwordConfirm !== password) {
      setFormError('Confirm password does not match')
    } else {
      setFormError('')
    }
  }, [passwordConfirm])

  const validate = () => {
    if (!password || password.length < 4) {
      setFormError('You must use at least 4 characters in your password')
      return false;
    }
    if (passwordConfirm !== password) {
      setFormError('Confirm password does not match')
      return false
    }
    setFormError('')
    return true
  }

  const submit = async (password) => {
    try {
      setError('')
      const res = await resetPassword({
        token,
        username,
        password,
      })
      if (res.data.success) {
        addNotification({
          status: 'success',
          content: 'Password reset successfully!'
        })
        navigate('/auth/login')
      } else {
        setError(res.data.error)
      }
    } catch (error) {
      setError(error)
    }
  }

  if (!window.location.search.startsWith('?token=')) {
    return 'Invalid Token!'
  }

  return (
    <Box alignItems='center' justifyContent='center' height='100vh' display='flex' flexDirection='column'>
      <Box display='flex' flexDirection='column'>
        <Image
          width={400}
          src={PalomaLogo}
          float='left'
          mb={5}
        />
        <Text fontSize={22}> Reset Password.</Text>
        <Text fontSize="sm">Username: {info.username}</Text>
        <Text fontSize="sm">Email: {info.email}</Text>
        {resetUsername && (
          <Box my={2}>
            <Text htmlFor="password">New Username</Text>
            <Input
              className="form-control"
              id="username"
              value={username}
              onChange={ev => setUsername(ev.target.value)}
            />
          </Box>
        )}
        <Box my={2}>
          <Text htmlFor="password">New Password</Text>
          <Input
            type="password"
            className="form-control"
            id="password"
            value={password}
            onChange={ev => setPassword(ev.target.value)}
          />
        </Box>
        <Box my={2}>
          <Text htmlFor="password">Confirm Password</Text>
          <Input
            type="password"
            className="form-control"
            id="password"
            value={passwordConfirm}
            onChange={ev => setPasswordConfirm(ev.target.value)}
          />
        </Box>
        <Text color='red'>{`${error || formError || ''}`}</Text>
        <Button
          mt={5}
          type="submit"
          className="btn btn-danger"
          onClick={() => {
            const v =validate()
            if (v) {
              submit(password)
            }
          }}
        >Reset Request</Button>
      </Box>
    </Box>
  )
}

export default ResetPasswordRequest
