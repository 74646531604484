import axiosInstance from './service'

const getSummary = async () => {
  const res = await axiosInstance.get(`/admin/summary`)
  return res
}

export {
  getSummary
}
