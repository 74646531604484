import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import jwt_decode from 'jwt-decode';

const useAuthorization = () => {
    const token = useSelector(state => state.user.token)

    const isAuthenticated = useCallback(() => {
        if (token) {
            const decoded = jwt_decode(token);
            const currentTime = Date.now() / 1000;
            if (decoded.exp >= currentTime) {
                return true;
            }
            return false;
        }
        return false;
    }, [token]);

    return { isAuthenticated };
};

export default useAuthorization;
