import { useState, useEffect, useMemo } from 'react'
import { Box, FormControl, FormLabel, Button, useDisclosure, Modal, ModalOverlay,
  ModalContent, FormErrorMessage, Textarea, TableContainer, Table, Thead, Tr, Th, Tbody, Td, HStack, Text } from '@chakra-ui/react'
import { containerWidth } from 'styles'
import { useForm } from 'react-hook-form'
import Card from 'components/Card'
import { FaCalendar, FaPen, FaTrash } from 'react-icons/fa'
import { getDatePart, formatDate } from 'utils'
import Error from 'components/Error'
import useTalent from 'hooks/talent'
import DateInput from 'components/DateInput'
import Sorter from 'components/Sorter'

const TalentBookOutDates = () => {
  const {
    handleSubmit,
    register,
    reset,
    watch,
    control,
    formState: { errors },
    getValues
  } = useForm()
  const {talent, updateTalent, updateTalentBook} = useTalent()
  const { isOpen, onOpen: openBookoutModal, onClose } = useDisclosure()
  const [apiError, setApiError] = useState('')
  const [cIdx, setCIdx] = useState(-1)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const [isAdd, setIsAdd] = useState(false);
  const [sort, setSort] = useState({
    start_date: 1,
  })

  const sortedTalents = useMemo(() => {
    let result = [...talent.bookouts];
    result = talent?.bookouts?.filter(bookout => !!bookout.start_date);
    switch (sort.start_date) {
      case 0:
        return result
      case -1:
        return result.sort((a, b) => new Date(b.start_date) - new Date(a.start_date));
      case 1:
        return result.sort((a, b) => new Date(a.start_date) - new Date(b.start_date));
      default:
        return result
    }
  }, [sort, talent])

  if (!talent) { return null }

  const saveBookout = async () => {
    const values = getValues()
    setIsSubmitting(true)
    try {
      let updatedBookouts = talent.bookouts.slice(0)
      if (cIdx === -1) { updatedBookouts.push(values) }
      else { updatedBookouts[cIdx] = values }
      if(isAdd){
        await updateTalentBook({ bookouts: updatedBookouts })
      } else {
        let editedBookout = updatedBookouts[cIdx];
        await updateTalent({ bookouts: updatedBookouts, editedBookout: editedBookout })
      }
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
    onClose()
  }

  const removeBookout = async (idx) => {
    let updatedBookouts = sortedTalents.slice(0)
    let deletedBookout = updatedBookouts[idx];
    updatedBookouts.splice(idx, 1)

    setIsSubmitting(true)
    try {
      await updateTalent({ bookouts: updatedBookouts, deletedBookout: deletedBookout })
    } catch (err) {
      setApiError(err)
    }
    setIsSubmitting(false)
  }


  return (
    <Box width={containerWidth} mx='auto'>
      <Button mb={3} disabled={isSubmitting} leftIcon={<FaCalendar />} onClick={() => {
        reset({})
        setCIdx(-1)
        openBookoutModal()
        setIsAdd(true)
      }}>
        Add Book-Out Date
      </Button>
      <Card title='Book-Out Dates' >
        <TableContainer>
          <Table>
            <Thead>
              <Tr>
                <Th>
                  <HStack>
                    <Text>Start Date</Text>
                    <Sorter value={sort.start_date} setValue={(v) => {
                      setSort({ ...sort, start_date: v })
                    }} />
                  </HStack>
                </Th>
                <Th>End Date</Th>
                <Th>Reason</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {sortedTalents.length === 0 && (
                <Tr>
                  <Td colSpan={4} textAlign='center'>You do not have any added book-out dates.</Td>
                </Tr>
              )}
              {sortedTalents.map((bookout, idx) => {
                return (
                  <Tr key={idx}>
                    <Td>{formatDate(bookout.start_date)}</Td>
                    <Td>{formatDate(bookout.end_date)}</Td>
                    <Td>{bookout.note}</Td>
                    <Td>
                      <Box display='grid' gridGap={2} gridAutoFlow='column'>
                        <FaPen onClick={() => {
                          reset({
                            ...bookout,
                            start_date: getDatePart(bookout.start_date),
                            end_date: getDatePart(bookout.end_date),
                          })
                          setCIdx(idx)
                          openBookoutModal()
                          setIsAdd(false)
                        }} />
                        <FaTrash onClick={() => {
                          removeBookout(idx)
                        }} />
                      </Box>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
      </Card>

      <Modal isOpen={isOpen} size='xl' onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <Card title='New Book-Out Date' bodyProps={{width: '100%',  px: 7}}>
            <form onSubmit={handleSubmit(saveBookout)}>
              <Box>
                <FormControl isInvalid={errors && errors.start_date}>
                  <FormLabel>Starting Date</FormLabel>
                  <DateInput control={control} name='start_date' rules={{
                    required: 'This field is required',
                    validate: (v) => {
                      if (isNaN(v)) {
                        return 'Start date should be a valid date'
                      }
                      if (+new Date(v) < +new Date()) {
                        return 'Start date should be greater than today'
                      }
                      return true
                    }
                  }} />
                  <FormErrorMessage>{(errors.start_date && errors.start_date.message)}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors && errors.end_date}>
                  <FormLabel>End Date</FormLabel>
                  <DateInput control={control} name='end_date' rules={{
                    required: 'This field is required',
                    validate: (v) => {
                      if (isNaN(v)) {
                        return 'End date should be a valid date'
                      }
                      if (+new Date(v) < +new Date(watch('start_date'))) {
                        return 'End date should be greater than start date'
                      }
                      return true
                    }
                  }} />
                  <FormErrorMessage>{(errors.end_date && errors.end_date.message)}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors && errors.note}>
                  <FormLabel>Reason *</FormLabel>
                  <Textarea {...register('note', {required: 'This field is required'})} />
                  <FormErrorMessage>{(errors.note && errors.note.message)}</FormErrorMessage>
                </FormControl>
                <Error error={apiError} />
                <Box mt={5} display='flex' justifyContent='center'>
                  <Button disabled={isSubmitting} isLoading={isSubmitting} mr={2} type='submit'>Save</Button>
                  <Button disabled={isSubmitting} onClick={onClose}>Cancel</Button>
                </Box>
              </Box>
            </form>
          </Card>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default TalentBookOutDates
