import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Box, Input, Text, Button, useDisclosure, Modal, ModalContent, ModalOverlay,
  FormControl, FormLabel, FormErrorMessage, Progress,
  TableContainer, Table, Thead, Tbody, Tr, Th, Td, Checkbox, HStack } from '@chakra-ui/react'
import { FaPen, FaPlus, FaTrash, FaChevronDown, FaChevronUp } from 'react-icons/fa'
import useUIContext from 'hooks/ui-context'
import { useForm } from 'react-hook-form'
import { containerWidth } from 'styles'
import Card from 'components/Card'
import Pagination from 'components/Pagination'
import useUser from 'hooks/users'
import Error from 'components/Error'
import LoadingBar from 'components/LoadingBar'
import { SUPER_ADMIN_USERNAMES } from 'constants'

const ORDER_BY_LIST = {
  username: 'username',
  first_name: 'first_name',
  last_name: 'last_name',
  email: 'email',
}

const ORDER_TYPE = {
  desc: 'desc',
  asc: 'asc'
}

const AdminsList = () => {
  const [pagination, setPagination] = useState({
    page: 0,
    perPage: 10,
    totalPages: 1
  })
  const { setBreadcrumbs, showConfirmModal } = useUIContext()
  const { users, updateUser, registerAdminUser, deleteUser, searchAdmins } = useUser()
  const [filter, setFilter] = useState({
    first_name: '',
    last_name: '',
    email: ''
  })
  const [orderBy, setOrderBy] = useState();
  const [orderType, setOrderType] = useState();
  const { isOpen, onOpen, onClose } = useDisclosure()
  const [editUser, setEditUser] = useState({})
  const [apiError, setApiError] = useState('')
  const [updatePwd, setUpdatePwd] = useState(false)
  const [saving, setSaving] = useState(false)
  const [loading, setLoading] = useState(false)
  const {
    handleSubmit,
    register,
    reset,
    watch,
    formState: { errors },
    getValues
  } = useForm()

  

  const handleSave = async () => {
    setSaving(true)
    try {
      const values = getValues()
      if (editUser._id) {
        await updateUser(editUser._id, values)
      } else {
        const createduser = await registerAdminUser({
          ...values,
        })
        await updateUser(createduser.data.user._id, values)
      }
      await loadData()
      onClose()
    } catch (err) {
      setApiError(err)
    }
    setSaving(false)
  }

  const deleteConfirmHandle = async (action, userId) => {
    if (action !== 'yes') { return }
    await deleteUser(userId)
    await loadData()
  }

  const loadData = async () => {
    setLoading(true)
    const { page, perPage } = pagination
    await searchAdmins({
      ...filter,
      page_num: page,
      per_page: perPage,
      ...!!orderBy && {order_by: orderBy},
      ...!!orderType && {order_type: orderType},
    })
    setLoading(false)
  }
  useEffect(() => {
    loadData()
  }, [pagination.page, pagination.perPage])

  useEffect(() => {
    if (editUser._id) {
      reset(editUser)
    }
  }, [editUser])

  useEffect(() => {
    setBreadcrumbs([{
      path: '/admin/dashboard',
      label: 'Dashboard'
    }])
  }, [])

  const renderSortIcon = (name) => {
    if(orderBy === name) {
      if(orderType === ORDER_TYPE.desc) {
        return <FaChevronUp />
      }
      if(orderType === ORDER_TYPE.asc) {
        return <FaChevronDown />
      }
    }
    return null
  }

  const handleSortby = (name) => () => {
    setOrderBy(name);
    if(orderBy === name) {
      switch (orderType) {
        case ORDER_TYPE.desc:
          setOrderType(ORDER_TYPE.asc)
          break;
        case ORDER_TYPE.asc:
          setOrderType(ORDER_TYPE.desc)
          break;
        default:
          setOrderType(ORDER_TYPE.desc)
          break;
      }
    } else {
      setOrderType(ORDER_TYPE.desc)
    }
  }

  useEffect(() => {
    if(!!orderBy && !!orderType) {
      loadData()
    }
  }, [orderBy, orderType])

  useEffect(() => {
    setPagination({
      ...pagination,
      totalPages: Math.ceil(users.total_count / users.per_page),
    });
  }, [users]);

  return (
    <Box width={containerWidth} mx='auto'>
      <Box mb={5}>
        <Text>Filters</Text>
        <Box mb={3} mt={1} display='grid' gridTemplateColumns={{ base: '1fr', md: 'auto repeat(3, 1fr) auto'}} gap={3}>
          <Button leftIcon={<FaPlus />} onClick={() => {
            onOpen()
            setEditUser({})
            setApiError('')
            reset({})
          }}>New</Button>
          <Input value={filter.first_name} placeholder='First name' onChange={(ev) => {
            setFilter({ ...filter, first_name: ev.target.value })
          }} />
          <Input value={filter.last_name} placeholder='Last name' onChange={(ev) => {
            setFilter({ ...filter, last_name: ev.target.value })
          }} />
          <Input type='email' value={filter.email} placeholder='Email' onChange={(ev) => {
            setFilter({ ...filter, email: ev.target.value })
          }} />
          <Button onClick={loadData}>Search</Button>
        </Box>
      </Box>
      <Card title='Admins' >
        <LoadingBar loading={loading} />
        <TableContainer mb={3}>
          <Table>
            <Thead>
              <Tr>
                <Th _hover={{cursor: 'pointer'}} onClick={handleSortby(ORDER_BY_LIST.username)}>
                  <Box display='flex'>
                    Username
                    <Text ml={2}>
                      {renderSortIcon(ORDER_BY_LIST.username)}
                    </Text>
                  </Box>
                </Th>
                <Th _hover={{cursor: 'pointer'}} onClick={handleSortby(ORDER_BY_LIST.first_name)}>
                  <Box display='flex'>
                    First name
                    <Text ml={2}>
                      {renderSortIcon(ORDER_BY_LIST.first_name)}
                    </Text>
                  </Box>
                </Th>
                <Th _hover={{cursor: 'pointer'}} onClick={handleSortby(ORDER_BY_LIST.last_name)}>
                  <Box display='flex'>
                    Last name
                    <Text ml={2}>
                      {renderSortIcon(ORDER_BY_LIST.last_name)}
                    </Text>
                  </Box>
                </Th>
                <Th _hover={{cursor: 'pointer'}} onClick={handleSortby(ORDER_BY_LIST.email)}>
                  <Box display='flex'>
                    Email
                    <Text ml={2}>
                      {renderSortIcon(ORDER_BY_LIST.email)}
                    </Text>
                  </Box>
                </Th>
                <Th>Actions</Th>
              </Tr>
            </Thead>
            <Tbody>
              {(users.users || []).map(user => {
                return (
                  <Tr key={user._id}>
                    <Td>{user.username}</Td>
                    <Td>{user.first_name}</Td>
                    <Td>{user.last_name}</Td>
                    <Td>{user.email}</Td>
                    <Td>
                      <Button variant='text' size='xs' onClick={() => {
                        setEditUser(user)
                        setApiError('')
                        onOpen()
                        setUpdatePwd(false)
                      }}>
                        <FaPen />
                      </Button>
                      <Button variant='text' size='xs' onClick={() => {
                        showConfirmModal({
                          title: 'Are you sure?',
                          content: 'You won\'t be able to revert this action!',
                          buttons: [{
                            label: 'Yes',
                            action: 'yes',
                            color: 'red'
                          }, {
                            label: 'Cancel',
                            action: 'cancel'
                          }],
                          payload: user._id,
                          callback: deleteConfirmHandle
                        })
                      }}>
                        <FaTrash />
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
            </Tbody>
          </Table>
        </TableContainer>
        <Pagination
          pagination={pagination}
          setPagination={setPagination}
          perPageCountIncludes={['1', '2', '10', '25', '50', '100']}
        />
      </Card>

      <Modal isOpen={isOpen} size='xl' onClose={() => {
        if (!saving) { onClose() }
      }}>
        <ModalOverlay />
        <ModalContent>
          <Card title={`${editUser._id ? 'Edit' : 'New'} Admin User`}>
            <form onSubmit={handleSubmit(handleSave)}>
              <Box display='grid' gridAutoFlow='row' gap={3}>
                <FormControl isInvalid={errors && errors.first_name}>
                  <FormLabel>First name *</FormLabel>
                  <Input {...register('first_name', { required: 'This field is required'})} />
                  <FormErrorMessage>{(errors && errors.first_name && errors.first_name.message)}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors && errors.last_name}>
                  <FormLabel>Last name *</FormLabel>
                  <Input {...register('last_name', { required: 'This field is required'})} />
                  <FormErrorMessage>{(errors && errors.last_name && errors.last_name.message)}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors && errors.username}>
                  <FormLabel>Username *</FormLabel>
                  <Input {...register('username', { required: 'This field is required'})} />
                  <FormErrorMessage>{(errors && errors.username && errors.username.message)}</FormErrorMessage>
                </FormControl>
                <FormControl isInvalid={errors && errors.email}>
                  <FormLabel>Email *</FormLabel>
                  <Input {...register('email', { required: 'This field is required'})} />
                  <FormErrorMessage>{(errors && errors.email && errors.email.message)}</FormErrorMessage>
                </FormControl>
                {(editUser._id) && (
                  <Checkbox onChange={(ev) => {
                    setUpdatePwd(ev.target.checked)
                  }}> Update Password </Checkbox>
                )}
                {(!editUser._id || updatePwd) && ([
                  <FormControl key='pwd' isInvalid={errors && errors.password}>
                    <FormLabel>Password *</FormLabel>
                    <Input type='password' {...register('password', { required: 'This field is required'})} />
                    <FormErrorMessage>{(errors && errors.password && errors.password.message)}</FormErrorMessage>
                  </FormControl>,
                  <FormControl key='pwd-confirm' isInvalid={errors && errors.confirm_password}>
                    <FormLabel>Confirm Password *</FormLabel>
                    <Input type='password' {...register('confirm_password', {
                      required: 'This field is required',
                      validate: (v) => watch('password') === v ? true : 'Confirm password doesn\'t match'
                    })} />
                    <FormErrorMessage>{(errors && errors.confirm_password && errors.confirm_password.message)}</FormErrorMessage>
                  </FormControl>
                ])}
                <Error error={apiError} />
                <HStack>
                  <Button type='submit' disabled={saving} isLoading={saving}>
                    Save
                  </Button>
                  <Button onClick={onClose} disabled={saving}>
                    Cancel
                  </Button>
                </HStack>
              </Box>
            </form>
          </Card>
        </ModalContent>
      </Modal>
    </Box>
  )
}

export default AdminsList
