import { Box, FormControl, FormLabel, Input } from '@chakra-ui/react';

const SocialInput = ({ socialPrefix, fieldname, register, setValue, placeholder }) => {
  return (
    <Input
      placeholder={placeholder || `${socialPrefix}username`}
      {...register(fieldname)}
      onChange={ev => {
        // If there's a prefix, replace it; otherwise, just use the value
        const v = socialPrefix ? ev.target.value.replace(socialPrefix, '') : ev.target.value;
        setValue(fieldname, v ? `${socialPrefix}${v}` : '');
      }}
    />
  );
};

const TalentSocialFormSection = ({ prefix = 'talent', register, setValue, boxProps = {} }) => {
  return (
    <Box {...boxProps}>
      <FormControl>
        <FormLabel>Instagram</FormLabel>
        <Box display='grid' gap={3}>
          <Input
            placeholder='Instagram profile URL'
            {...register(`${prefix}.social_links.instagram`)}
          />
          <Input placeholder='# followers (numbers only)' type='number' {...register(`${prefix}.social_links.instagram_followers`)} />
        </Box>
      </FormControl>

      <FormControl>
        <FormLabel>TikTok</FormLabel>
        <Box display='grid' gap={3}>
          <Input
            placeholder='TikTok profile URL'
            {...register(`${prefix}.social_links.tiktok`)}
          />
          <Input placeholder='# followers (numbers only)' type='number' {...register(`${prefix}.social_links.tiktok_followers`)} />
        </Box>
      </FormControl>

      <FormControl>
        <FormLabel>YouTube</FormLabel>
        <Box display='grid' gap={3}>
          <Input
            placeholder='YouTube profile URL'
            {...register(`${prefix}.social_links.youtube`)}
          />
          <Input placeholder='# followers (numbers only)' type='number' {...register(`${prefix}.social_links.youtube_followers`)} />
        </Box>
      </FormControl>

      <FormControl>
        <FormLabel>Personal Website</FormLabel>
        <Box display='grid' gap={3}>
          <Input placeholder='https://your-website-domain' {...register(`${prefix}.social_links.personal`)} />
        </Box>
      </FormControl>

      <FormControl>
        <FormLabel>IMDb</FormLabel>
        <Box display='grid' gap={3}>
          <Input
            placeholder='Public IMDb URL'
            {...register(`${prefix}.social_links.imdb`)}
          />
        </Box>
      </FormControl>

      <FormControl>
        <FormLabel>Facebook</FormLabel>
        <Box display='grid' gap={3}>
          <Input
              placeholder='Facebook profile URL'
              {...register(`${prefix}.social_links.facebook`)}
          />
        </Box>
      </FormControl>
    </Box>
  );
};

export default TalentSocialFormSection;
