import { Box, Text } from '@chakra-ui/react'
import jwt_decode from "jwt-decode"
import { Link } from 'react-router-dom'
import { useNavigate } from 'react-router-dom'
import React, { useEffect, useState } from 'react'
import { useSearchParams } from 'react-router-dom'
import { talentResponseUpdate } from 'services'
import useUIContext from 'hooks/ui-context'

const TalentChoice = () => {
  const [searchParams] = useSearchParams()
  const token = searchParams.get('token')
  const choice = searchParams.get('choice')
  const navigate = useNavigate()
  const [user, setUser] =useState(null)
  const {addNotification } = useUIContext()

  useEffect(() => {
    const saveChoice = async () => {
      try {
        window.token = token
        const userObj = jwt_decode(token)
        setUser(userObj)
        await talentResponseUpdate(choice)
        addNotification({
          status: 'success',
          content: 'Your choice is submitted!'
        })
        if (choice === 'yes') {
          navigate(`/auth/reset-password?token=${token}&resetUsername=true`)
        }
      } catch (err) {
        console.log('err: ', err);
        navigate('/')
      }
    }
    if (token && choice) {
      saveChoice()
    }
  }, [token, choice])

  if (!user) { return null }
  
  

  return (
    <Box alignItems='center' justifyContent='center' height='100vh' display='flex' flexDirection='column'>
      <Text fontSize='xl' textAlign='center'>
        Thank you for your reply {user.first_name} {user.last_name}. Your choice - "{choice}"
      </Text>
      {choice === 'yes' &&
      <Text fontSize='sm' textAlign='center' mb={5} mt={1}>
        You can reset password and login from here -&nbsp;
        <Link to={`/auth/reset-password-request?username=${user && user.username}&resetUsername=true`}>Reset Password</Link>
      </Text>
      }
    </Box>
  )
}

export default TalentChoice
