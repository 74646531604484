import { createSlice } from '@reduxjs/toolkit'
import { searchClients, updateUser, registerUser, deleteUser, searchAdmins, registerAdminUser } from 'services'

const userSlice = createSlice({
  name: 'user',
  initialState: {
    currentUser: null,
    users: [],
    token: localStorage.getItem('token')
  },
  reducers: {
    setCurrentUser: (state, action) => {
      state.currentUser = action.payload
    },
    setToken: (state, action) => {
      const token = action.payload
      state.token = token
      if (token) {
        localStorage.setItem('token', token)
        document.cookie = `token=${token}`
      } else {
        state.currentUser = null;
        localStorage.removeItem('token')
        document.cookie = 'token=; expires=Thu, 01 Jan 1970 00:00:00 UTC;'
      }
    },
    setUsers: (state, action) => {
      state.users = action.payload
    }
  }
})

export const searchClientsAndSet = async (search, dispatch) => {
  const userRes = await searchClients(search)
  dispatch(setUsers(userRes.data))
}

export const updateUserAndSet = async (id, fields, dispatch) => {
  return await updateUser(id, fields)
}

export const registerUserAndSet = async (fields, dispatch) => {
  return await registerUser(fields)
}

export const deleteUserAndSet = async (id, dispatch) => {
  return await deleteUser(id)
}

export const searchAdminsAndSet = async (search, dispatch) => {
  const userRes = await searchAdmins(search)
  dispatch(setUsers(userRes.data))
}

export const registerAdminUserAndSet = async (fields, dispatch) => {
  return await registerAdminUser(fields)
}

export const {
  setCurrentUser,
  setToken,
  setUsers
} = userSlice.actions

export default userSlice.reducer
