import { Text, Modal, ModalOverlay, ModalContent } from '@chakra-ui/react'
import { useSelector } from 'react-redux'

const RootLoading = () => {
  const rootLoading = useSelector(state => state.app.rootLoading)

  return (
    <Modal isOpen={rootLoading} isCentered size='xs'>
      <ModalOverlay />
      <ModalContent>
        <Text textAlign='center' my={3}>
          Loading...
        </Text>
      </ModalContent>
    </Modal>
  )
}

export default RootLoading
